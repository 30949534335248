<template>
  <transition name="loader" mode="out-in" v-if="loading">
    <div class="loader">
      <div class="loader_wrapper">
        <div class="img_wrapper">
          <img src="@/assets/images/login_logo_small.png" alt="" />
        </div>

        <div class="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "animatedLoader",

  props: ["loading"],
};
</script>

<style scoped>
.loader {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(45, 44, 44, 0.95);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader_wrapper {
  display: flex;
  flex-direction: column;
  /* background: red; */
}

.img_wrapper {
  width: 150px;
  height: 150px;
}

.img_wrapper img {
  width: 100%;
  height: auto;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 20px;
  margin: auto;
}
.lds-ellipsis div {
  position: absolute;
  top: 5px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
</style>
