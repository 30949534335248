import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

import homeRoutes from "./homeRoutes";
import aboutRoutes from "./aboutRoutes";
import servicesRoutes from "./servicesRoutes";
import projectsRoutes from "./projectsRoutes";
import contactRoutes from "./contactRoutes";
import NotFound from "@/components/NotFound";

let allRoutes = [];

const routes = [
  ...allRoutes.concat(
    homeRoutes,
    aboutRoutes,
    servicesRoutes,
    projectsRoutes,
    contactRoutes
  ),
  {
    path: "*",
    name: "NotFound",
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
