<template>
  <div class="add_client">
    <!-- Animated Loader -->
    <animated-loader :loading="isLoading" />

    <h4 class="section_title">Home/Clients</h4>

    <button class="single_tab">Add Client</button>

    <div class="content_wrapper">
      <form @submit.prevent="submitForm">
        <div class="english_inputs">
          <div class="form_row">
            <div>
              <div class="input_control upload_image">
                <label> Client Photo </label>
                <input
                  id="imagePath"
                  type="text"
                  class="disabled_input"
                  disabled
                  placeholder="Choose file"
                />
                <GrayButton text="Browse" size="small" browseBtn="en">
                  <input
                    @change="uploadImage"
                    id="fileInput"
                    type="file"
                    accept="image/"
                  />
                </GrayButton>
              </div>
            </div>
            <div>
              <div class="input_control">
                <label>Client Name</label>
                <input
                  type="text"
                  placeholder="Enter Client Name"
                  v-model="enClientName"
                  @keypress="$checkInputCharacters(enClientName, $event)"
                />
                <span class="hint">
                  {{ enClientName.length }}/50 (Maximum 50 characters)
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="line"></div>

        <div class="arabic_inputs">
          <div class="form_row">
            <div></div>
            <div>
              <div class="input_control">
                <label>اسم العميل</label>
                <input
                  type="text"
                  placeholder="ادخل اسم العميل"
                  v-model="arClientName"
                  @keypress="$checkInputCharacters(arClientName, $event)"
                />
                <span class="hint"
                  >(أقصى عدد من الأحرف {{ "50".toIndiaDigits() }}){{
                    String(arClientName.length).toIndiaDigits()
                  }}/{{ "50".toIndiaDigits() }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="action_btns">
          <button
            type="button"
            class="cancel_btn"
            @click="$modal.show('cancelAddCleintModal')"
          >
            Cancel
          </button>
          <GrayButton text="Confirm" size="large" />
        </div>
      </form>
    </div>

    <!-- Cancel Modal -->
    <modal name="cancelAddCleintModal">
      <div class="modal_content">
        <div class="img_wrapper">
          <img src="@/assets/icons/warning_icon.svg" alt="" />
        </div>
        <div class="text_content">
          <h4>Cancel Add client process?</h4>
          <p>You will not continue this process</p>
        </div>
        <div class="action_btns">
          <button type="button" @click="$modal.hide('cancelAddCleintModal')">
            Keep adding
          </button>
          <button type="button" @click="cancelAddClient">Yes, Cancel</button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import GrayButton from "../UI/GrayButton.vue";
import { AddClient } from "@/endPoints/home";
import AnimatedLoader from "../UI/AnimatedLoader.vue";

export default {
  components: {
    GrayButton,
    AnimatedLoader,
  },

  data() {
    return {
      //  Upload logo
      uploadedImages: "",

      // Input
      enClientName: "",
      arClientName: "",

      isLoading: false,
    };
  },

  methods: {
    //  Upload image
    uploadImage() {
      const fileInput = document.getElementById("fileInput");
      const imagePath = document.getElementById("imagePath");
      const file = fileInput.files[0];
      imagePath.value = file.name;

      const reader = new FileReader();

      reader.onloadend = () => {
        this.uploadedImages = reader.result;
      };

      reader.readAsDataURL(file);
    },

    // Submit form
    async submitForm() {
      if (this.uploadedImages == "") {
        this.$noty.warning("Please select client image");
      } else if (this.enClientName == "") {
        this.$noty.warning("Please enter english client name");
      } else if (this.arClientName == "") {
        this.$noty.warning("Please enter arabic client name");
      } else if (this.enClientName.length > 50) {
        this.$noty.warning("Client name can't be more than 50 characters");
      } else if (this.arClientName.length > 50) {
        this.$noty.warning("Client name can't be more than 50 characters");
      } else {
        this.isLoading = true;
        const res = await AddClient(
          this.enClientName,
          this.arClientName,
          this.uploadedImages
        );

        if (res) {
          this.isLoading = false;
          this.$noty.success("Client has been added successfully");
          this.$router.push("/");
        }
      }
    },

    cancelAddClient() {
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
.add_client .action_btns {
  display: flex;
  gap: 30px;
  justify-content: flex-end;
}
</style>
