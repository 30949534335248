<template>
  <div id="login">
    <div class="login_content">
      <div class="logo">
        <div class="img_wrapper">
          <img src="@/assets/images/login_logo_large.png" alt="" />
        </div>
      </div>
      <div class="centred_wrapper">
        <div class="login_form">
          <div class="form_logo">
            <img src="@/assets/images/login_logo_small.png" alt="" />
          </div>
          <h4 class="form_title">Log in</h4>

          <form @submit.prevent="submitForm">
            <div class="input_control">
              <label>Email</label>
              <input
                type="email"
                name="email"
                placeholder="Enter your email"
                v-model="email"
              />
            </div>
            <div class="input_control">
              <label>Password</label>
              <input
                type="password"
                name="password"
                placeholder="Enter your password"
                v-model="password"
              />
            </div>
            <input type="submit" value="Log in" />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { login } from "@/endPoints/auth";
import { EventBus } from "@/eventBus";
export default {
  name: "LoginView",

  data() {
    return {
      email: "",
      password: "",
    };
  },

  methods: {
    async submitForm() {
      if (this.email == "") {
        this.$noty.warning("Please enter your email");
      } else if (this.password == "") {
        this.$noty.warning("Please enter your password");
      } else {
        try {
          const response = await login(this.email, this.password);
          const data = response.data;

          if (data.code == 200) {
            EventBus.$emit("Authorized");
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
};
</script>

<style scoped>
#login {
  background: #333333;
  min-height: 100vh;
}

#login .login_content {
  display: flex;
  height: 100vh;
}

#login .login_content > div {
  flex: 1;
}

#login .logo {
  border-right: 3px solid #bbbbbb;
  display: flex;
  justify-content: center;
  align-items: center;
}

#login .logo .img_wrapper {
  width: 61%;
  height: 61%;
}

#login .logo .img_wrapper img {
  width: 100%;
  height: auto;
}

#login .login_form {
  width: 73%;
  min-height: 69.5%;
}

#login .form_logo {
  width: 27%;
  height: 27%;
  margin: 0 auto 25px;
}

#login .form_logo img {
  width: 100%;
  height: auto;
}

#login .form_title {
  font-size: 27px;
  color: #f2eded;
  text-align: center;
  margin-bottom: 70px;
}

#login .input_control:first-child {
  margin-bottom: 30px;
}

#login .input_control:nth-child(2) {
  margin-bottom: 70px;
}

#login form input[type="submit"] {
  border: 0;
  height: 40px;
  width: 67%;
  display: block;
  margin: auto;
  font-size: 14px;
  font-weight: 600;
  background-color: #707070;
  color: #fff;
  border-radius: var(--border_radius);
  cursor: pointer;
}
</style>
