<template>
  <div>
    <button
      v-if="browseBtn"
      type="button"
      class="gray_btn"
      :class="[
        position == 'right' ? 'right' : '',
        size == 'large' ? 'large' : '',
        size == 'small' ? 'small' : '',
        browseBtn == 'en' ? 'browse_btn_en' : '',
        browseBtn == 'ar' ? 'browse_btn_ar' : '',
      ]"
    >
      <slot></slot>
      {{ text }}
    </button>

    <button
      v-if="size == 'large'"
      class="gray_btn"
      :class="[
        position == 'right' ? 'right' : '',
        size == 'large' ? 'large' : '',
      ]"
    >
      <slot></slot>
      {{ text }}
    </button>
  </div>
</template>

<script>
export default {
  name: "GrayButton",
  props: {
    text: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      required: true,
    },
    position: {
      type: String,
      required: false,
    },
    browseBtn: {
      type: String,
      required: false,
    },
  },
};
</script>

<style scoped>
.gray_btn {
  font-family: "Roboto", sans-serif;
  position: relative;
  display: block;
  border: 0;
  outline: 0;
  background-color: #707070;
  color: #fff7f7;
  border-radius: 20px;
  cursor: pointer;
}

.gray_btn.right {
  margin-left: auto;
}

.gray_btn.large {
  width: 200px;
  height: 45px;
  font-size: 14px;
}

.gray_btn.small {
  width: 130px;
  height: 36px;
  font-size: 12px;
}

.gray_btn.browse_btn_en {
  position: absolute;
  bottom: 0;
  right: 0;
}

.gray_btn.browse_btn_ar {
  position: absolute;
  bottom: 0;
  left: 0;
}
</style>
