<template>
  <div class="our_projects">
    <!-- Animated Loader -->
    <animated-loader :loading="isLoading" />

    <h4 class="section_title">Our projects</h4>

    <button class="single_tab">Projects categories</button>

    <div class="content_wrapper">
      <div class="flex_wrapper">
        <div class="categories">
          <button
            @click="CategorizeProjects()"
            :class="selectedCategoryId == '' ? 'active' : ''"
          >
            All
          </button>
          <button
            v-for="category in allCategories"
            :key="category.id"
            :class="category.id == selectedCategoryId ? 'active' : ''"
            @click="CategorizeProjects(category.id)"
          >
            {{ category.name_en }}
          </button>

          <router-link to="/projects/add-category">
            <div class="add_category_btn">
              <button>Add New Category</button>
            </div>
          </router-link>
        </div>
        <div class="projects_wrapper">
          <div class="projects">
            <router-link
              :to="'/projects/edit-project/' + project.id"
              v-for="project in categorizedProjects"
              :key="project.id"
            >
              <div class="single_project">
                <div class="img_wrapper">
                  <img :src="project.image" alt="project_name" />
                  <span>{{ project.name_en }}</span>
                </div>
              </div>
            </router-link>

            <router-link
              :to="'/projects/add-project/' + selectedCategoryId"
              v-if="selectedCategoryId != ''"
            >
              <div class="single_project add_project">
                <div class="img_wrapper">
                  <img src="@/assets/icons/upload_icon.svg" alt="" />
                  <span>Add Project</span>
                </div>
              </div>
            </router-link>
          </div>

          <!-- Action Buttons -->
          <div class="action_btns" v-if="selectedCategoryId != ''">
            <button class="delete_category_btn" @click="showDeleteModal">
              Delete Category
            </button>

            <router-link :to="'/projects/add-project/' + selectedCategoryId">
              <GrayButton
                text="Add New Project"
                position="right"
                size="large"
              />
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <!-- Delete Category Modal -->
    <modal name="deleteCategorymodal">
      <div class="modal_content">
        <div class="img_wrapper">
          <img src="@/assets/icons/warning_icon.svg" alt="" />
        </div>
        <div class="text_content">
          <h4>Delete This Category?</h4>
          <p>You will also delete all included projects in this category</p>
        </div>
        <div class="action_btns">
          <button type="button" @click="$modal.hide('deleteCategorymodal')">
            Cancel
          </button>
          <button type="button" @click="deleteSingleCategory">
            Yes, Delete it
          </button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import GrayButton from "@/components/UI/GrayButton.vue";
import {
  getCategories,
  deleteCategory,
  getProjects,
} from "@/endPoints/projects";
import AnimatedLoader from "@/components/UI/AnimatedLoader.vue";

export default {
  components: {
    GrayButton,
    AnimatedLoader,
  },

  data() {
    return {
      allCategories: [],
      selectedCategoryId: "",

      projects: [],
      categorizedProjects: [],

      isLoading: false,
    };
  },

  methods: {
    async getAllCategories() {
      this.isLoading = true;
      const res = await getCategories();

      if (res) {
        this.isLoading = false;
        this.allCategories = res.data.data;
      }
    },

    async CategorizeProjects(id) {
      if (id) {
        this.selectedCategoryId = id;
        this.categorizedProjects = this.projects.filter((project) => {
          return project.category.id == id;
        });
      } else {
        this.selectedCategoryId = "";
        this.categorizedProjects = this.projects;
      }
    },

    async getAllProjects() {
      this.isLoading = true;
      const res = await getProjects();

      if (res) {
        this.isLoading = false;
        this.projects = res.data.data;
        this.categorizedProjects = this.projects;
      }
    },

    showDeleteModal() {
      if (this.selectedCategoryId == "") {
        this.$noty.warning("Please select a category");
      } else {
        this.$modal.show("deleteCategorymodal");
      }
    },

    async deleteSingleCategory() {
      this.isLoading = true;
      const res = await deleteCategory(this.selectedCategoryId);
      if (res) {
        this.isLoading = false;
        this.$noty.success("Category has been deleted successfully");
        this.$modal.hide("deleteCategorymodal");
        this.selectedCategoryId = "";
        this.getAllCategories();
        this.getAllProjects();
      }
    },
  },

  mounted() {
    this.getAllCategories();
    this.getAllProjects();
  },
};
</script>

<style scoped>
.our_projects .content_wrapper {
  padding: 0px 60px 0px 0px;
}

.our_projects .single_tab {
  width: 220px;
  border: 0;
  outline: 0;
  padding: 18px 0 18px 20px;
  background-color: #333333;
  color: #d3d3d3;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  font-size: 16px;
  text-align: left;
  border-bottom: 3px solid #2d2c2c;
}

.flex_wrapper {
  display: flex;
}

.flex_wrapper .categories {
  width: 220px;
  padding-top: 55px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
}

.flex_wrapper .categories > button {
  width: 100%;
  border: 0;
  background-color: transparent;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  padding: 15px 0 15px 20px;
  text-align: left;
  cursor: pointer;
}

.flex_wrapper .categories button.active {
  background-color: #2d2c2c;
}

.add_category_btn button {
  display: block;
  width: 160px;
  height: 35px;
  background-color: #707070;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  margin: 30px auto 0;
  border: 0;
  border-radius: 15px;
  cursor: pointer;
}

.projects_wrapper {
  flex: 1;
  padding-bottom: 40px;
}

.projects {
  min-height: 380px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  gap: 50px;
  padding-top: 55px;
  padding-left: 40px;
  margin-bottom: 80px;
}

.projects .single_project {
  height: 150px;
  border: 3px solid #2d2c2c;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.projects .img_wrapper {
  text-align: center;
}

.projects .img_wrapper img {
  width: 55px;
  height: 55px;
  margin-bottom: 20px;
}

.projects .single_project span {
  display: block;
  font-size: 12px;
  text-align: center;
  font-family: "Roboto", sans-serif;
}

.projects .single_project.add_project span {
  color: #494949;
}

.our_projects .content_wrapper .action_btns > button {
  width: 200px;
  height: 45px;
  background-color: #ff4a4a;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  position: relative;
  display: block;
  border: 0;
  outline: 0;
  color: #fff7f7;
  border-radius: 20px;
  cursor: pointer;
}
</style>
