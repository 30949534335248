<template>
  <div id="home">
    <h4 class="section_title">Home/{{ theSelectedView }}</h4>

    <!-- Tabs -->
    <NavigationTabs
      :tabs="tabs"
      :selectView="selectView"
      :theSelectedView="theSelectedView"
    />

    <!-- Home Content -->
    <div class="content_wrapper">
      <!-- Hero Section -->
      <HeroSection v-if="theSelectedView == 'Hero Section'" />
      <!-- Services -->
      <ServicesSection v-if="theSelectedView == 'Services'" />
      <!-- Clients -->
      <ClientsSection v-if="theSelectedView == 'Clients'" />
      <!-- Fast Facts -->
      <FastFactsSection v-if="theSelectedView == 'JOE13 Fast Facts'" />

            <!-- Fast Facts -->
      <BlogsSecation v-if="theSelectedView == 'Blogs'" />


    </div>
  </div>
</template>

<script>
import NavigationTabs from "@/components/UI/NavigationTabs.vue";
import HeroSection from "@/components/home/HeroSection.vue";
import ServicesSection from "@/components/home/ServicesSection.vue";
import ClientsSection from "@/components/home/ClientsSection.vue";
import FastFactsSection from "@/components/home/FastFactsSection.vue";
import BlogsSecation from "@/components/home/BlogsSecation.vue";

export default {
  components: {
    NavigationTabs,
    HeroSection,
    ServicesSection,
    ClientsSection,
    FastFactsSection,
    BlogsSecation,
  },

  data() {
    return {
      /* Tabs */
      tabs: ["Hero Section", "Services", "Clients", "JOE13 Fast Facts", "Blogs"],
      theSelectedView: "",
    };
  },

  methods: {
    /* Tabs */
    selectView(value) {
      this.theSelectedView = value;
      localStorage.setItem("homeSelectedView", this.theSelectedView);
    },
  },

  mounted() {
    /* Tabs */
    this.theSelectedView = localStorage.getItem("homeSelectedView")
      ? localStorage.getItem("homeSelectedView")
      : "Hero Section";
  },
};
</script>

<style scoped></style>
