views
<template>
  <div>
    <LoginView v-if="!authentecatied" />
    <LayoutView v-else />
  </div>
</template>

<script>
import LoginView from "@/views/LoginView.vue";
import LayoutView from "@/views/LayoutView.vue";
import { EventBus } from "@/eventBus";
import { getAuthStatus } from "@/helpers";

export default {
  name: "ChoosedView",
  components: {
    LoginView,
    LayoutView,
  },

  data() {
    return {
      authentecatied: false,
    };
  },

  async created() {
    this.authentecatied = getAuthStatus();
    EventBus.$on("unauthorized", () => {
      this.$noty.warning("Unauthorized login");
      this.$router.push("/login");
    });
    EventBus.$on("wrongPasswrodOrEmail", () => {
      this.$noty.warning("wrong email or password");
    });
    EventBus.$on("Authorized", () => {
      window.location.replace("/");
    });
  },
};
</script>

<style scoped></style>
