<template>
  <div class="clients_wrapper">
    <!-- Animated Loader -->
    <animated-loader :loading="isLoading" />

    <!-- clients section form -->
    <form @submit.prevent="submitForm">
      <div class="english_inputs">
        <div class="textarea_control">
          <label>Section Description</label>
          <textarea
            placeholder="Enter section description"
            v-model="enSectionDescription"
            @keypress="$checkTextareaCharacters(enSectionDescription, $event)"
          ></textarea>
          <span class="hint">
            {{ enSectionDescription.length }}/450 (Maximum 450 characters)
          </span>
        </div>
      </div>

      <div class="line"></div>

      <div class="arabic_inputs">
        <div class="textarea_control">
          <label>وصف القسم</label>
          <textarea
            placeholder="ادخل وصف القسم"
            dir="rtl"
            v-model="arSectionDescription"
            @keypress="$checkTextareaCharacters(arSectionDescription, $event)"
          ></textarea>
          <span class="hint">
            {{ String(arSectionDescription.length).toIndiaDigits() }}/{{
              "450".toIndiaDigits()
            }}
            (أقصى عدد من الأحرف {{ "450".toIndiaDigits() }})
          </span>
        </div>
      </div>

      <div class="action_btns">
        <GrayButton text="Save&Submit" size="large" position="right" />
      </div>
    </form>

    <!-- Cleints -->
    <div class="clients">
      <router-link
        :to="'/edit-client/' + client.id"
        v-for="client in allClients"
        :key="client.id"
      >
        <div class="single_client">
          <div class="img_wrapper">
            <img :src="client.image" alt="" />
            <span>{{ client.name_en }}</span>
          </div>
        </div>
      </router-link>

      <router-link to="/add-client">
        <div class="single_client add_client">
          <div class="img_wrapper">
            <img src="@/assets/icons/upload_icon.svg" alt="" />
            <span>Add Client</span>
          </div>
        </div>
      </router-link>
    </div>
    <router-link to="/add-client">
      <GrayButton text="Add Client" position="right" size="large" />
    </router-link>
  </div>
</template>

<script>
import GrayButton from "../UI/GrayButton.vue";
import {
  sendClientsDescription,
  getClientsDescription,
  getClients,
} from "@/endPoints/home";
import AnimatedLoader from "../UI/AnimatedLoader.vue";

export default {
  components: {
    GrayButton,
    AnimatedLoader,
  },

  data() {
    return {
      enSectionDescription: "",
      arSectionDescription: "",

      allClients: [],

      isLoading: false,
    };
  },

  methods: {
    async submitForm() {
      if (this.enSectionDescription == "") {
        this.$noty.warning("Please enter english section description");
      } else if (this.enSectionDescription.length > 450) {
        this.$noty.warning(
          "Section description can't be more than 450 characters"
        );
      } else if (this.arSectionDescription == "") {
        this.$noty.warning("Please enter arabic section description");
      } else if (this.arSectionDescription.length > 450) {
        this.$noty.warning(
          "Section description can't be more than 450 characters"
        );
      } else {
        this.isLoading = true;
        const res = await sendClientsDescription(
          this.arSectionDescription,
          this.enSectionDescription
        );
        if (res.data) {
          this.isLoading = false;
          this.$noty.success("Description has been updated successfully");
        }
      }
    },

    async getSectionDescription() {
      this.isLoading = true;
      const res = await getClientsDescription();
      const data = res.data.data;

      if (res) {
        this.isLoading = false;
        this.arSectionDescription = data.brief_ar;
        this.enSectionDescription = data.brief_en;
      }
    },

    async getAllClients() {
      this.isLoading = true;
      const res = await getClients();
      if (res) {
        this.isLoading = false;
        this.allClients = res.data.data;
      }
    },
  },

  mounted() {
    this.getSectionDescription();
    this.getAllClients();
  },
};
</script>

<style scoped>
.clients {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  gap: 40px;
  margin-bottom: 60px;
}

.clients_wrapper form {
  margin-bottom: 45px;
}

.clients_wrapper .textarea_control {
  margin-bottom: 0px !important;
}

.clients .single_client {
  height: 150px;
  border: 3px solid #2d2c2c;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.clients .img_wrapper {
  text-align: center;
}

.clients .img_wrapper img {
  width: 55px;
  height: 55px;
  margin-bottom: 20px;
}

.clients .single_client span {
  display: block;
  font-size: 11px;
  text-align: center;
  font-family: "Roboto", sans-serif;
}

.clients .add_client span {
  color: #494949;
}
</style>
