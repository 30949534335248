<template>
  <div class="our_team">
    <!-- Animated Loader -->
    <animated-loader :loading="isLoading" />

    <form @submit.prevent="submitForm">
      <div class="english_inputs">
        <div class="textarea_control">
          <label>Section Description</label>
          <textarea
            placeholder="Enter section description"
            v-model="enSectionDescription"
            @keypress="$checkTextareaCharacters(enSectionDescription, $event)"
          ></textarea>
          <span class="hint">
            {{ enSectionDescription.length }}/450 (Maximum 450 characters)
          </span>
        </div>
      </div>

      <div class="line"></div>

      <div class="arabic_inputs">
        <div class="textarea_control">
          <label>وصف القسم</label>
          <textarea
            placeholder="ادخل وصف القسم"
            dir="rtl"
            v-model="arSectionDescription"
            @keypress="$checkTextareaCharacters(arSectionDescription, $event)"
          ></textarea>
          <span class="hint">
            {{ String(arSectionDescription.length).toIndiaDigits() }}/{{
              "450".toIndiaDigits()
            }}
            (أقصى عدد من الأحرف {{ "450".toIndiaDigits() }})
          </span>
        </div>
      </div>

      <GrayButton text="Save&Submit" size="large" position="right" />
    </form>

    <div class="line"></div>

    <div class="team_members">
      <router-link
        :to="'/edit-team-member/' + teamMember.id"
        v-for="teamMember in allTeamMembers"
        :key="teamMember.id"
      >
        <div class="single_member">
          <div class="img_wrapper">
            <img :src="teamMember.image" alt="" />
            <span>{{ teamMember.name_en }}</span>
            <span>{{ teamMember.position_en }}</span>
          </div>
        </div>
      </router-link>

      <router-link to="/add-team-member">
        <div class="single_member add_member">
          <div class="img_wrapper">
            <img src="@/assets/icons/upload_icon.svg" alt="" />
            <span>Add Team Member</span>
          </div>
        </div>
      </router-link>
    </div>
    <router-link to="/add-team-member">
      <GrayButton text="Add Team Member" size="large" position="right" />
    </router-link>
  </div>
</template>

<script>
import GrayButton from "../UI/GrayButton.vue";
import {
  sendOurTeamDescription,
  getOurTeamDescription,
  getTeamMembers,
} from "@/endPoints/aboutUs";
import AnimatedLoader from "../UI/AnimatedLoader.vue";

export default {
  components: {
    GrayButton,
    AnimatedLoader,
  },

  data() {
    return {
      enSectionDescription: "",
      arSectionDescription: "",

      allTeamMembers: [],

      isLoading: false,
    };
  },

  methods: {
    async submitForm() {
      if (this.enSectionDescription == "") {
        this.$noty.warning("Please enter english section description");
      } else if (this.enSectionDescription.length > 450) {
        this.$noty.warning(
          "Section description can't be more than 450 characters"
        );
      } else if (this.arSectionDescription == "") {
        this.$noty.warning("Please enter arabic section description");
      } else if (this.arSectionDescription.length > 450) {
        this.$noty.warning(
          "Section description can't be more than 450 characters"
        );
      } else {
        this.isLoading = true;
        const res = await sendOurTeamDescription(
          this.arSectionDescription,
          this.enSectionDescription
        );
        if (res) {
          this.isLoading = false;
          this.$noty.success(
            "Our team description has been submitted successfully"
          );
        }
      }
    },

    async fetchourTeamDescription() {
      this.isLoading = true;
      const res = await getOurTeamDescription();

      if (res) {
        this.isLoading = false;
        const data = res.data.data;
        this.enSectionDescription = data.brief_en;
        this.arSectionDescription = data.brief_ar;
      }
    },

    async getAllteamMembers() {
      this.isLoading = true;
      const res = await getTeamMembers();

      if (res) {
        this.isLoading = false;
        this.allTeamMembers = res.data.data;
      }
    },
  },

  mounted() {
    this.fetchourTeamDescription();
    this.getAllteamMembers();
  },
};
</script>

<style scoped>
.our_team form {
  margin-bottom: 55px;
}

.our_team .textarea_control {
  margin-bottom: 0px;
}

.team_members {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  gap: 40px;
  margin: 60px 0;
}

.team_members .single_member {
  height: 162px;
  border: 3px solid #2d2c2c;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.team_members .img_wrapper {
  text-align: center;
}

.team_members .img_wrapper img {
  width: 75px;
  height: 75px;
  border-radius: 15px;
  margin-bottom: 5px;
}

.team_members .single_member span {
  display: block;
  font-size: 11px;
  text-align: center;
  font-family: "Roboto", sans-serif;
}

.team_members .single_member span:first-of-type {
  font-size: 14px;
  margin-bottom: 3px;
}

.team_members .add_member span {
  color: #494949;
  font-size: 11px !important;
}
</style>
