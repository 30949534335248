/* eslint-disable no-unused-vars */
import axios from "axios";
import { baseUrl } from "./app.config";
import { getHeaderConfig, clearAuth } from "./helpers";
import { EventBus } from "./eventBus";

export let api = axios.create({
  baseURL: baseUrl,
  timeout: 100000,
  headers: getHeaderConfig().headers,
});

api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status == 401) {
      if (error.response.data.errors == "Error_in_Email_or_Password") {
        EventBus.$emit("wrongPasswrodOrEmail");
      } else {
        clearAuth();
        EventBus.$emit("unauthorized");
      }
    }
  }
);
