<template>
  <div>
    <!-- Start Sidebar -->
    <aside>
      <div class="sidebar_logo">
        <img src="@/assets/images/joe13_sidebar_logo.png" alt="" />
      </div>
      <div class="sidebar_nav">
        <nav>
          <ul>
            <li @click="selectRoute('home')">
              <router-link to="/" exact="">
                <div class="icon_wrapper">
                  <img src="@/assets/icons/home_icon.svg" alt="" />
                </div>
                Home
              </router-link>
            </li>

            <li @click="selectRoute('about_us')">
              <router-link to="/about">
                <div class="icon_wrapper">
                  <img src="@/assets/icons/about_us_icon.svg" alt="" />
                </div>
                About Us
              </router-link>
            </li>

            <!-- <li @click="selectRoute('services')">
              <router-link to="/services">
                <div class="icon_wrapper">
                  <img src="@/assets/icons/services_icon.svg" alt="" />
                </div>
                Services
              </router-link>
            </li> -->

            <li @click="selectRoute('our_projects')">
              <router-link to="/projects">
                <div class="icon_wrapper">
                  <img src="@/assets/icons/our_projects_icon.svg" alt="" />
                </div>
                Our Projects
              </router-link>
            </li>

            <li @click="selectRoute('contact_us')">
              <router-link to="/contact">
                <div class="icon_wrapper">
                  <img src="@/assets/icons/contact_us_icon.svg" alt="" />
                </div>
                Contact Us
              </router-link>
            </li>
          </ul>
        </nav>
      </div>

      <div class="logout_btn">
        <button @click="$modal.show('warningModal')">Logout</button>
      </div>
    </aside>
    <!-- End Sidebar -->

    <!-- Start Header -->
    <!-- <header>
      <div class="search_input_wrapper">
        <input type="search" placeholder="Search" />
        <div class="image_wrapper">
          <img src="@/assets/icons/search_icon.svg" alt="" />
        </div>
      </div>
    </header> -->
    <!-- End Header -->

    <!-- Start Main -->
    <main>
      <router-view />
    </main>
    <!-- End Main -->

    <!-- Logout Modal -->
    <WarnningModal :modalData="deleteModalData" />
  </div>
</template>

<script>
import { clearAuth } from "@/helpers";
import WarnningModal from "@/components/UI/WarnningModal.vue";
export default {
  components: { WarnningModal },
  name: "LayoutView",

  data() {
    return {
      active_route: "",

      deleteModalData: {
        question: "Do you want to logout?",
        warnning: "You will be redirected to login page",
        cancelBtnText: "Cancel",
        actionBtnText: "Yes",
        actionFunc: this.logOut,
      },
    };
  },

  methods: {
    selectRoute(route) {
      this.active_route = route;
      localStorage.setItem("selectedRoute", route);
    },

    logOut() {
      clearAuth();
      this.$modal.hide("warningModal");
      this.$router.push("/");
      window.location.reload();
    },
  },

  mounted() {
    this.active_route = localStorage.getItem("selectedRoute")
      ? localStorage.getItem("selectedRoute")
      : "home";
  },
};
</script>

<style scoped>
/* Sidebar Styles */
aside {
  width: 10%;
  background-color: #333333;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 4px 0 6px rgba(0, 0, 0, 0.16);
  z-index: 2;
}

aside .sidebar_logo {
  text-align: center;
  padding-top: 20px;
  margin-bottom: 40px;
}

aside .sidebar_nav {
  margin: auto;
  text-align: center;
}

aside .sidebar_nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

aside .sidebar_nav ul li {
  margin-bottom: 5px;
  font-size: 14px;
  transition: var(--transition);
  cursor: pointer;
}

aside .sidebar_nav ul li a {
  display: block;
  padding: 10px 0;
}

aside .sidebar_nav ul li a.router-link-active {
  background-color: #2d2c2c;
}

aside .sidebar_nav ul li .icon_wrapper {
  width: 35px;
  height: 35px;
  margin: 0 auto 5px;
}

aside .sidebar_nav ul li .icon_wrapper img {
  width: 100%;
  height: auto;
}

aside .sidebar_nav ul li a {
  color: #d3d3d3;
  text-decoration: none;
}

aside .logout_btn {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

aside .logout_btn button {
  width: 100px;
  height: 36px;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  border: 0;
  outline: 0;
  background-color: #707070;
  color: #fff7f7;
  border-radius: 20px;
  cursor: pointer;
}

/* Header Styles */
/* header {
  width: 100%;
  height: 90px;
  background-color: #333333;
  margin-left: 10%;
  padding-left: 70px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.16);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
}

header .search_input_wrapper {
  width: 38%;
  position: relative;
}

header .search_input_wrapper input {
  outline: none;
  width: 100%;
  height: 36px;
  background-color: transparent;
  color: #707070;
  border: 1px solid #707070;
  border-radius: 20px;
  font-size: 12px;
  padding: 0 40px 0 25px;
}

[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
}

header .search_input_wrapper .image_wrapper {
  width: 15px;
  height: 15px;
  position: absolute;
  top: 25%;
  right: 25px;
}

header .search_input_wrapper .image_wrapper img {
  width: 100%;
  height: auto;
} */

/* Main Styles */
main {
  background-color: transparent;
  padding: 60px 75px 45px 75px;
  margin-left: 10%;
  /* padding-top: 150px; */
  /* box-shadow: inset 4px 0 6px rgba(0, 0, 0, 0.16); */
}
</style>
