<template>
  <div class="edit_project">
    <!-- Animated Loader -->
    <animated-loader :loading="isLoading" />

    <h4 class="section_title">Our projects/Mobily</h4>

    <button class="single_tab">{{ enProjectName }}</button>

    <div class="content_wrapper">
      <form @submit.prevent="submitForm">
        <div class="english_inputs">
          <div class="icons">
            <div class="service_icon">
              <div class="img_wrapper">
                <img :src="projectImage" alt="" />
              </div>
              <span>{{ enProjectName }}</span>
            </div>
            <button
              type="button"
              class="delete_icon"
              @click="$modal.show('deleteProjectModal')"
            >
              <img src="@/assets/icons/delete_icon.svg" alt="" />
            </button>
          </div>

          <div class="form_row">
            <div>
              <div class="input_control upload_image">
                <label> Project Photo</label>
                <input
                  id="imagePath"
                  type="text"
                  class="disabled_input"
                  disabled
                  placeholder="Choose file"
                />
                <GrayButton text="Browse" size="small" browseBtn="en">
                  <input
                    @change="uploadImage"
                    id="imageInput"
                    type="file"
                    accept="image/*"
                  />
                </GrayButton>
              </div>
            </div>
            <div>
              <div class="input_control">
                <label>Project Name</label>
                <input
                  type="text"
                  placeholder="Enter name"
                  v-model="enProjectName"
                  @keypress="$checkInputCharacters(enProjectName, $event)"
                />
                <span class="hint">
                  {{ enProjectName.length }}/50 (Maximum 50 characters)
                </span>
              </div>
            </div>
          </div>

          <div class="textarea_control">
            <label>Section Description</label>
            <textarea
              placeholder="Enter section description"
              v-model="enProjectDescription"
              @keypress="$checkTextareaCharacters(enProjectDescription, $event)"
            ></textarea>
            <span class="hint">
              {{ enProjectDescription.length }}/450 (Maximum 450 characters)
            </span>
          </div>

          <div class="form_row">
            <div>
              <div class="input_control upload_image">
                <label> Project Gallery Photos</label>
                <input
                  id="galleryImagePath"
                  type="text"
                  class="disabled_input"
                  disabled
                  placeholder="Choose file"
                />
                <GrayButton text="Browse" size="small" browseBtn="en">
                  <input
                    @change="uploadgalleryImages"
                    id="galleryImageInput"
                    type="file"
                    accept="image/*"
                    multiple
                  />
                </GrayButton>
              </div>
              <span class="hint"> 5 photos maximum </span>
            </div>
            <div>
              <div class="gallery_images">
                <div
                  class="images_wrapper"
                  v-if="
                    projectGalleryImages.length > 0 ||
                    uploadedProjectGalleryImages.length > 0
                  "
                >
                  <!-- Images received from server -->
                  <div
                    class="single_item"
                    v-for="galleryImage in projectGalleryImages"
                    :key="galleryImage.id"
                  >
                    <div class="img_wrapper">
                      <img :src="galleryImage.name" alt="" />
                    </div>
                    <div
                      class="delete_image_icon"
                      @click="deleteProjectGalleryimg(galleryImage.id)"
                    >
                      <img src="@/assets/icons/close_icon.svg" alt="" />
                    </div>
                  </div>
                  <!-- Images uploaded from user -->
                  <div
                    class="single_item"
                    v-for="(
                      galleryImage, index
                    ) in uploadedProjectGalleryImages"
                    :key="(galleryImage, index)"
                  >
                    <div class="img_wrapper">
                      <img :src="galleryImage" alt="" />
                    </div>
                    <div
                      class="delete_image_icon"
                      @click="deleteUploadedimage(index)"
                    >
                      <img src="@/assets/icons/close_icon.svg" alt="" />
                    </div>
                  </div>
                </div>

                <span v-else>No photo/s choosed</span>
              </div>
            </div>
          </div>
        </div>

        <div class="line"></div>

        <div class="arabic_inputs">
          <div class="form_row">
            <div></div>
            <div>
              <div class="input_control">
                <label> اسم المشروع </label>
                <input
                  type="text"
                  placeholder=" أدخل عنوان"
                  dir="rtl"
                  v-model="arProjectName"
                  @keypress="$checkInputCharacters(arProjectName, $event)"
                />
                <span class="hint"
                  >(أقصى عدد من الأحرف {{ "50".toIndiaDigits() }}){{
                    String(arProjectName.length).toIndiaDigits()
                  }}/{{ "50".toIndiaDigits() }}
                </span>
              </div>
            </div>
          </div>

          <div class="textarea_control">
            <label>وصف المشروع</label>
            <textarea
              placeholder="ادخل وصف الخدمة"
              dir="rtl"
              v-model="arProjectDescription"
              @keypress="$checkTextareaCharacters(arProjectDescription, $event)"
            ></textarea>
            <span class="hint">
              {{ String(arProjectDescription.length).toIndiaDigits() }}/{{
                "450".toIndiaDigits()
              }}
              (أقصى عدد من الأحرف {{ "450".toIndiaDigits() }})
            </span>
          </div>
        </div>

        <GrayButton text="Save&Submit" size="large" position="right" />
      </form>
    </div>

    <!-- Delete Modal -->
    <modal name="deleteProjectModal">
      <div class="modal_content">
        <div class="img_wrapper">
          <img src="@/assets/icons/warning_icon.svg" alt="" />
        </div>
        <div class="text_content">
          <h4>Delete this project?</h4>
          <p>You will not be able to recover it</p>
        </div>
        <div class="action_btns">
          <button type="button" @click="$modal.hide('deleteProjectModal')">
            Cancel
          </button>
          <button type="button" @click="deleteSingleProject">
            Yes, Delete it
          </button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import GrayButton from "../UI/GrayButton.vue";
import {
  getSingleProject,
  editSingleProject,
  deleteGalleryImage,
  deleteProject,
} from "@/endPoints/projects";
import AnimatedLoader from "../UI/AnimatedLoader.vue";

export default {
  components: {
    GrayButton,
    AnimatedLoader,
  },

  data() {
    return {
      // Images
      uploadedProjectImage: "",
      projectImage: "",

      fileList: [],
      uploadedProjectGalleryImages: [],
      uploadedProjectGalleryImage: "",
      projectGalleryImages: [],

      allImagesCount: "",

      // Inputs
      enProjectName: "",
      enProjectDescription: "",
      arProjectName: "",
      arProjectDescription: "",

      projectId: this.$route.params.id,

      isLoading: false,
    };
  },

  methods: {
    uploadImage() {
      const imageInput = document.getElementById("imageInput");
      const imagePath = document.getElementById("imagePath");
      const file = imageInput.files[0];
      imagePath.value = file.name;

      const reader = new FileReader();
      reader.onload = (e) => {
        this.uploadedProjectImage = e.target.result;
      };
      reader.readAsDataURL(file);
    },

    uploadgalleryImages(e) {
      this.fileList = e.target.files;
      let i;
      for (i = 0; i <= this.fileList.length; i++) {
        if (this.fileList && this.fileList[i]) {
          const reader = new FileReader();
          reader.onload = async (e) => {
            this.uploadedProjectGalleryImage = e.target.result;

            this.uploadedProjectGalleryImages.push(
              this.uploadedProjectGalleryImage
            );
            this.allImagesCount++;
          };
          reader.readAsDataURL(this.fileList[i]);
        }
      }
    },

    deleteUploadedimage(index) {
      this.uploadedProjectGalleryImages.splice(index, 1);
    },

    async submitForm() {
      if (this.uploadedProjectImage == "") {
        this.$noty.warning("Please select project image");
      } else if (this.allImagesCount > 5) {
        this.$noty.warning(
          "Project gallery images can't be more than 5 images"
        );
      } else if (this.enProjectName == "") {
        this.$noty.warning("Please enter english project name");
      } else if (this.enProjectDescription == "") {
        this.$noty.warning("Please enter english project description");
      } else if (this.enProjectName.length > 50) {
        this.$noty.warning("Project name can't be more than 50 characters");
      } else if (this.enProjectDescription.length > 450) {
        this.$noty.warning(
          "Project description can't be more than 450 characters"
        );
      } else if (this.arProjectName == "") {
        this.$noty.warning("Please enter arabic project name");
      } else if (this.arProjectName.length > 50) {
        this.$noty.warning("Project name can't be more than 50 characters");
      } else if (this.arProjectDescription == "") {
        this.$noty.warning("Please enter arabic project description");
      } else if (this.arProjectDescription.length > 450) {
        this.$noty.warning(
          "Project description can't be more than 450 characters"
        );
      } else {
        this.isLoading = true;
        const res = await editSingleProject(
          this.projectId,
          this.arProjectName,
          this.enProjectName,
          this.arProjectDescription,
          this.enProjectDescription,
          this.uploadedProjectImage,
          this.uploadedProjectGalleryImages
        );

        if (res) {
          this.isLoading = false;
          this.uploadedProjectGalleryImages = [];
          this.$noty.success("Project has been edited successfully");
          this.$router.push("/projects");
        }
      }
    },

    async getProject() {
      this.isLoading = true;
      const res = await getSingleProject(this.projectId);
      if (res) {
        this.isLoading = false;
        const data = res.data.data;
        this.projectImage = data.image;
        this.projectGalleryImages = data.images;
        this.enProjectName = data.name_en;
        this.enProjectDescription = data.description_en;
        this.arProjectName = data.name_ar;
        this.arProjectDescription = data.description_ar;
        this.allImagesCount = this.projectGalleryImages.length;
      }
    },

    async deleteProjectGalleryimg(id) {
      this.isLoading = true;
      const res = await deleteGalleryImage(id);

      if (res) {
        this.isLoading = false;
        this.getProject();
      }
    },

    async deleteSingleProject() {
      this.isLoading = true;
      const res = await deleteProject(this.projectId);

      if (res) {
        this.isLoading = false;
        this.$noty.success("Project has been deleted successfully");
        this.$router.push("/projects");
      }
    },
  },

  mounted() {
    this.getProject();
  },
};
</script>

<style scoped>
.edit_project .icons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.edit_project .arabic_inputs .icons {
  justify-content: flex-end;
}

.edit_project .icons .service_icon {
  width: 135px;
  height: 100px;
  padding: 10px 0;
  border: 3px solid #2d2c2c;
  border-radius: 20px;
}

.edit_project .icons .service_icon .img_wrapper {
  width: 45px;
  height: 45px;
  margin: 0 auto 15px;
}

.edit_project .icons .service_icon .img_wrapper img {
  width: 100%;
  height: 100%;
}

.edit_project .icons .service_icon span,
.edit_project .description_photo span {
  display: block;
  font-family: "Roboto", sans-serif;
  font-size: 9px;
  text-align: center;
}

.edit_project .icons .delete_icon {
  background-color: transparent;
  border: 0;
  outline: 0;
  align-self: flex-start;
  cursor: pointer;
}

.edit_project .icons .delete_icon img {
  width: 35px;
}

.edit_project .form_row:nth-child(4) {
  align-items: center;
}

.edit_project .gallery_images {
  padding: 25px 45px;
  border: 2px solid #4d4d4d;
  border-radius: 25px;
}

.edit_project .gallery_images .images_wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.edit_project .gallery_images .single_item {
  position: relative;
}

.edit_project .gallery_images .img_wrapper {
  width: 60px;
  height: 60px;
}

.edit_project .gallery_images .img_wrapper img {
  width: 100%;
  height: 100%;
}

.edit_project .gallery_images .single_item .delete_image_icon {
  position: absolute;
  top: -10px;
  right: -10px;
  cursor: pointer;
}
</style>
