<template>
  <div>
    <!-- Animated Loader -->
    <animated-loader :loading="isLoading" />

    <div class="logo_preview">
      <span>Website Logo</span>
      <div class="img_wrapper">
        <img id="logoPreview" :src="logoPath" alt="" />
      </div>
    </div>
    <form @submit.prevent="submitForm">
      <div class="english_inputs">
        <div class="form_row">
          <div>
            <div class="input_control upload_image">
              <label> Upload New Logo </label>
              <input
                id="imagePath"
                type="text"
                class="disabled_input"
                disabled
                placeholder="Choose file"
              />
              <GrayButton text="Browse" size="small" browseBtn="en">
                <input
                  @change="uploadLogo"
                  id="fileInput"
                  type="file"
                  accept="image/*"
                />
              </GrayButton>
            </div>
          </div>
          <div>
            <div class="input_control">
              <label>Section Headline</label>
              <input
                type="text"
                placeholder="Enter section headline"
                v-model="enSectionTitle"
                @keypress="$checkInputCharacters(enSectionTitle, $event)"
              />
              <span class="hint">
                {{ enSectionTitle.length }}/50 (Maximum 50 characters)
              </span>
            </div>
          </div>
        </div>
        <div class="textarea_control">
          <label>Section Description</label>
          <textarea
            placeholder="Enter section description"
            v-model="enSectionDescription"
            @keypress="$checkTextareaCharacters(enSectionDescription, $event)"
          ></textarea>
          <span class="hint">
            {{ enSectionDescription.length }}/450 (Maximum 450 characters)
          </span>
        </div>
      </div>

      <div class="line"></div>

      <div class="arabic_inputs">
        <div class="form_row">
          <div></div>
          <div>
            <div class="input_control">
              <label> عنوان القسم</label>
              <input
                type="text"
                placeholder=" أدخل عنوان القسم"
                dir="rtl"
                v-model="arSectionTitle"
                @keypress="$checkInputCharacters(arSectionTitle, $event)"
              />
              <span class="hint"
                >(أقصى عدد من الأحرف {{ "50".toIndiaDigits() }}){{
                  String(arSectionTitle.length).toIndiaDigits()
                }}/{{ "50".toIndiaDigits() }}
              </span>
            </div>
          </div>
        </div>

        <div class="textarea_control">
          <label>وصف القسم</label>
          <textarea
            placeholder="ادخل وصف القسم"
            dir="rtl"
            v-model="arSectionDescription"
            @keypress="$checkTextareaCharacters(arSectionDescription, $event)"
          ></textarea>
          <span class="hint">
            {{ String(arSectionDescription.length).toIndiaDigits() }}/{{
              "450".toIndiaDigits()
            }}
            (أقصى عدد من الأحرف {{ "450".toIndiaDigits() }})
          </span>
        </div>
      </div>

      <GrayButton text="Save&Submit" size="large" position="right" />
    </form>
  </div>
</template>

<script>
import GrayButton from "../UI/GrayButton.vue";
import {
  sendHeroContent,
  getHeroContent,
  uploadLogoimage,
} from "@/endPoints/home";
import AnimatedLoader from "../UI/AnimatedLoader.vue";

export default {
  components: {
    GrayButton,
    AnimatedLoader,
  },

  data() {
    return {
      //  Upload logo
      uploadedLogo: "",
      logoPath: "",

      // Inputs
      enSectionTitle: "",
      enSectionDescription: "",
      arSectionTitle: "",
      arSectionDescription: "",

      isLoading: false,
    };
  },
  methods: {
    //  Upload logo
    uploadLogo(e) {
      // eslint-disable-next-line no-unused-vars
      const fileInput = document.getElementById("fileInput");
      const imagePath = document.getElementById("imagePath");
      imagePath.value = fileInput.value.substring(12);
      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        this.uploadedLogo = reader.result;
      };
      reader.readAsDataURL(file);
    },

    /* Submit form */
    async submitForm() {
      if (this.enSectionTitle == "") {
        this.$noty.warning("Please enter english section title");
      } else if (this.enSectionDescription == "") {
        this.$noty.warning("Please enter english section description");
      } else if (this.enSectionTitle.length > 50) {
        this.$noty.warning("Section title can't be more than 50 characters");
      } else if (this.enSectionDescription.length > 450) {
        this.$noty.warning(
          "Section description can't be more than 450 characters"
        );
      } else if (this.arSectionTitle == "") {
        this.$noty.warning("Please enter arabic section title");
      } else if (this.arSectionDescription == "") {
        this.$noty.warning("Please enter arabic section description");
      } else if (this.arSectionTitle.length > 50) {
        this.$noty.warning("Section title can't be more than 50 characters");
      } else if (this.arSectionDescription.length > 450) {
        this.$noty.warning(
          "Section description can't be more than 450 characters"
        );
      } else {
        this.isLoading = true;
        const res = await sendHeroContent(
          this.arSectionTitle,
          this.enSectionTitle,
          this.arSectionDescription,
          this.enSectionDescription
        );
        if (res) {
          this.isLoading = false;
          this.$noty.success("Content has been updated successfully");
        }

        if (this.uploadedLogo) {
          const res = await uploadLogoimage(this.uploadedLogo);
          if (res) {
            this.logoPath = res.data.data;
          }
        }
      }
    },

    async fetchtHeroContent() {
      this.isLoading = true;
      const res = await getHeroContent();
      const data = res.data.data;

      if (res.data) {
        this.isLoading = false;
        this.enSectionTitle = data.title_en;
        this.enSectionDescription = data.description_en;
        this.arSectionTitle = data.title_ar;
        this.arSectionDescription = data.description_ar;
        this.logoPath = data.logo;
      }
    },
  },

  mounted() {
    this.fetchtHeroContent();
  },
};
</script>

<style scoped>
.logo_preview {
  width: 100px;
  margin-bottom: 45px;
}

.logo_preview span {
  display: block;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  margin-bottom: 10px;
  text-align: center;
}

.logo_preview .img_wrapper {
  width: 60px;
  height: 60px;
  background-color: #2d2c2c;
  border: 2px solid #707070;
  border-radius: 50%;
  margin: auto;
  padding: 2px;
  overflow: hidden;
}

.logo_preview img {
  width: 100%;
  height: auto;
}
</style>
