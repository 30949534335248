import AboutView from "@/views/AboutView";
import AddTeamMember from "@/components/about/AddTeamMember";
import EditTeamMember from "@/components/about/EditTeamMember";

const aboutRoutes = [
  {
    path: "/about",
    name: "AboutView",
    component: AboutView,
  },
  {
    path: "/add-team-member",
    name: "AddTeamMember",
    component: AddTeamMember,
  },
  {
    path: "/edit-team-member/:id",
    name: "EditTeamMember",
    component: EditTeamMember,
  },
];

export default aboutRoutes;
