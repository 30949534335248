import OurProjects from "@/views/OurProjects";
import AddCategory from "@/components/projects/AddCategory";
import AddProject from "@/components/projects/AddProject";
import EditProject from "@/components/projects/EditProject";

const projectsRoutes = [
  {
    path: "/projects",
    name: "OurProjects",
    component: OurProjects,
  },
  {
    path: "/projects/add-category",
    name: "AddCategory",
    component: AddCategory,
  },
  {
    path: "/projects/add-project/:id",
    name: "AddProject",
    component: AddProject,
  },
  {
    path: "/projects/edit-project/:id",
    name: "EditProject",
    component: EditProject,
  },
];

export default projectsRoutes;
