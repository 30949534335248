<template>
  <div class="edit_service">
    <!-- Animated Loader -->
    <animated-loader :loading="isLoading" />

    <h4 class="section_title">Home/Services/{{ enSectionTitle }}</h4>

    <button class="single_tab">{{ enSectionTitle }}</button>

    <div class="content_wrapper">
      <form @submit.prevent="submitForm">
        <div class="english_inputs">
          <div class="icons">
            <div class="service_icon">
              <div class="img_wrapper">
                <img :src="logo" alt="" />
              </div>
              <span>{{ enSectionTitle }}</span>
            </div>
            <button
              type="button"
              class="delete_icon"
              @click="$modal.show('deleteServiceModal')"
            >
              <img src="@/assets/icons/delete_icon.svg" alt="" />
            </button>
          </div>
          <div class="form_row">
            <div>
              <div class="input_control upload_image">
                <label> Service Icon </label>
                <input
                  id="logoPath"
                  type="text"
                  class="disabled_input"
                  disabled
                  placeholder="Choose file"
                />
                <GrayButton text="Browse" size="small" browseBtn="en">
                  <input
                    @change="uploadLogo"
                    id="logoInput"
                    type="file"
                    accept="image/*"
                  />
                </GrayButton>
              </div>
            </div>
            <div>
              <div class="input_control">
                <label>Service Title</label>
                <input
                  type="text"
                  placeholder="Enter title"
                  v-model="enSectionTitle"
                  @keypress="$checkInputCharacters(enSectionTitle, $event)"
                />
                <span class="hint">
                  {{ enSectionTitle.length }}/50 (Maximum 50 characters)
                </span>
              </div>
            </div>
          </div>
          <div class="form_row">
            <div>
              <div class="input_control upload_image">
                <label> Description Photo </label>
                <input
                  id="imagePath"
                  type="text"
                  class="disabled_input"
                  disabled
                  placeholder="Choose file"
                />
                <GrayButton text="Browse" size="small" browseBtn="en">
                  <input
                    id="imageInput"
                    @change="uploadImage"
                    type="file"
                    accept="image/*"
                  />
                </GrayButton>
              </div>
            </div>
            <div class="description_photo">
              <div class="content">
                <div class="img_wrapper">
                  <img :src="image" alt="" />
                </div>
                <span> Preview your photo here </span>
              </div>
            </div>
          </div>
          <div class="textarea_control">
            <label>Section Description</label>
            <textarea
              placeholder="Enter section description"
              v-model="enSectionDescription"
              @keypress="$checkTextareaCharacters(enSectionDescription, $event)"
            ></textarea>
            <span class="hint">
              {{ enSectionDescription.length }}/450 (Maximum 450 characters)
            </span>
          </div>
        </div>

        <div class="line"></div>

        <div class="arabic_inputs">
          <div class="icons">
            <div class="service_icon">
              <div class="img_wrapper">
                <img :src="logo" alt="service_logo" />
              </div>
              <span>{{ arSectionTitle }}</span>
            </div>
          </div>

          <div class="form_row">
            <div></div>
            <div>
              <div class="input_control">
                <label> عنوان الخدمة</label>
                <input
                  type="text"
                  placeholder=" أدخل عنوان"
                  dir="rtl"
                  v-model="arSectionTitle"
                  @keypress="$checkInputCharacters(arSectionTitle, $event)"
                />
                <span class="hint"
                  >(أقصى عدد من الأحرف {{ "50".toIndiaDigits() }}){{
                    String(arSectionTitle.length).toIndiaDigits()
                  }}/{{ "50".toIndiaDigits() }}
                </span>
              </div>
            </div>
          </div>

          <div class="textarea_control">
            <label>وصف القسم</label>
            <textarea
              placeholder="ادخل وصف الخدمة"
              dir="rtl"
              v-model="arSectionDescription"
              @keypress="$checkTextareaCharacters(arSectionDescription, $event)"
            ></textarea>
            <span class="hint">
              {{ String(arSectionDescription.length).toIndiaDigits() }}/{{
                "450".toIndiaDigits()
              }}
              (أقصى عدد من الأحرف {{ "450".toIndiaDigits() }})
            </span>
          </div>
        </div>

        <GrayButton text="Save&Submit" size="large" position="right" />
      </form>
    </div>

    <!-- Delete Modal -->
    <modal name="deleteServiceModal">
      <div class="modal_content">
        <div class="img_wrapper">
          <img src="@/assets/icons/warning_icon.svg" alt="" />
        </div>
        <div class="text_content">
          <h4>Delete This Service?</h4>
          <p>You will not be able to recover it</p>
        </div>
        <div class="action_btns">
          <button @click="$modal.hide('deleteServiceModal')">Cancel</button>
          <button @click="deleteSingleService">Yes, Delete it</button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import GrayButton from "@/components/UI/GrayButton.vue";

import {
  getSingleService,
  updateService,
  deleteService,
} from "@/endPoints/home";
import AnimatedLoader from "../UI/AnimatedLoader.vue";

export default {
  components: {
    GrayButton,
    AnimatedLoader,
  },

  data() {
    return {
      // Logo and Image
      uploadedLogo: "",
      logo: "",
      uploadedImage: "",
      image: "",

      // Inputs
      enSectionTitle: "",
      enSectionDescription: "",
      arSectionTitle: "",
      arSectionDescription: "",

      service_id: this.$route.params.id,
      service: "",

      isLoading: false,
    };
  },

  methods: {
    uploadLogo(e) {
      const logoInput = document.getElementById("logoInput");
      const logoPath = document.getElementById("logoPath");
      logoPath.value = logoInput.value.substring(12);
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        this.uploadedLogo = reader.result;
      };
      reader.readAsDataURL(file);
    },

    uploadImage(e) {
      const imageInput = document.getElementById("imageInput");
      const imagePath = document.getElementById("imagePath");
      imagePath.value = imageInput.value.substring(12);
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        this.uploadedImage = reader.result;
      };
      reader.readAsDataURL(file);
    },

    async submitForm() {
      if (this.uploadedLogo == "") {
        this.$noty.warning("Please select service icon");
      } else if (this.uploadedImage == "") {
        this.$noty.warning("Please select description image");
      } else if (this.enSectionTitle == "") {
        this.$noty.warning("Please enter english section title");
      } else if (this.enSectionDescription == "") {
        this.$noty.warning("Please enter english section description");
      } else if (this.enSectionTitle.length > 50) {
        this.$noty.warning("Section title can't be more than 50 characters");
      } else if (this.enSectionDescription.length > 450) {
        this.$noty.warning(
          "Section description can't be more than 450 characters"
        );
      } else if (this.arSectionTitle == "") {
        this.$noty.warning("Please enter arabic section title");
      } else if (this.arSectionDescription == "") {
        this.$noty.warning("Please enter arabic section description");
      } else if (this.arSectionTitle.length > 50) {
        this.$noty.warning("Section title can't be more than 50 characters");
      } else if (this.arSectionDescription.length > 450) {
        this.$noty.warning(
          "Section description can't be more than 450 characters"
        );
      } else {
        this.isLoading = true;
        const res = await updateService(
          this.service_id,
          this.arSectionTitle,
          this.enSectionTitle,
          this.arSectionDescription,
          this.enSectionDescription,
          this.uploadedLogo,
          this.uploadedImage
        );

        if (res) {
          this.isLoading = false;

          this.$noty.success("Service has been edit successfully");
          this.$router.push("/");
        }
      }
    },

    async getService() {
      this.isLoading = true;
      const res = await getSingleService(this.service_id);

      if (res) {
        this.isLoading = false;
        const data = res.data.data;
        this.logo = data.icon;
        this.image = data.image;
        this.enSectionTitle = data.title_en;
        this.enSectionDescription = data.description_en;
        this.arSectionTitle = data.title_ar;
        this.arSectionDescription = data.description_ar;
      }
    },

    async deleteSingleService() {
      this.isLoading = true;
      const res = await deleteService(this.service_id);
      if (res) {
        this.isLoading = false;
        this.$noty.success("Service has been deleted successfully");
        this.$router.push("/");
      }
    },
  },

  mounted() {
    this.getService();
  },
};
</script>

<style scoped>
.edit_service .action_btns {
  display: flex;
  gap: 30px;
  justify-content: flex-end;
}

.edit_service .icons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.edit_service .arabic_inputs .icons {
  justify-content: flex-end;
}

.edit_service .icons .service_icon {
  width: 135px;
  height: 100px;
  padding: 10px 0;
  border: 3px solid #2d2c2c;
  border-radius: 20px;
}

.edit_service .icons .service_icon .img_wrapper {
  width: 45px;
  height: 45px;
  margin: 0 auto 15px;
}

.edit_service .icons .service_icon .img_wrapper img {
  width: 100%;
  height: auto;
}

.edit_service .icons .service_icon span,
.edit_service .description_photo span {
  display: block;
  font-family: "Roboto", sans-serif;
  font-size: 9px;
  text-align: center;
}

.edit_service .icons .delete_icon {
  background-color: transparent;
  border: 0;
  outline: 0;
  align-self: flex-start;
  cursor: pointer;
}

.edit_service .icons .delete_icon img {
  width: 35px;
}

.edit_service .form_row div.description_photo {
  display: flex;
  justify-content: flex-start;
}

.edit_service .description_photo .img_wrapper {
  width: 75px;
  height: 75px;
  margin: auto;
}

.edit_service .description_photo .img_wrapper img {
  width: 100%;
  height: auto;
}

.edit_service .description_photo span {
  display: block;
  font-family: "Roboto", sans-serif;
  font-size: 9px;
  text-align: center;
}
</style>
