/* eslint-disable no-unused-vars */
import { api } from "@/axios.config";

export const sendAboutUsDescription = (
  about_us_description_ar,
  about_us_description_en
) => {
  const res = api.post("api/admin/create_about_us", {
    about_us_description_ar,
    about_us_description_en,
  });

  if (res) {
    return res;
  } else return false;
};

export const getAboutUsDescription = () => {
  const res = api.get("api/get_about_us");

  if (res) {
    return res;
  } else return false;
};

export const sendOurTeamDescription = (brief_ar, brief_en) => {
  const res = api.post("api/admin/create_team_brief", {
    brief_ar,
    brief_en,
  });

  if (res) {
    return res;
  } else return false;
};

export const getOurTeamDescription = () => {
  const res = api.get("api/get_team_brief");

  if (res) {
    return res;
  } else return false;
};

export const AddTeamMember = (
  name_en,
  name_ar,
  position_ar,
  position_en,
  image
) => {
  const res = api.post("api/admin/create_team_member", {
    name_en,
    name_ar,
    position_ar,
    position_en,
    image,
  });

  if (res) {
    return res;
  } else return false;
};

export const getTeamMembers = () => {
  const res = api.get("api/get_team_members");

  if (res) {
    return res;
  } else return false;
};

export const getSingleTeamMember = (teamMemberId) => {
  const res = api.get(`api/get_team_member/${teamMemberId}`);

  if (res) {
    return res;
  } else return false;
};

export const editTeamMember = (
  teamMemberId,
  name_en,
  name_ar,
  position_ar,
  position_en,
  image
) => {
  const res = api.put(`api/admin/update_team_member/${teamMemberId}`, {
    name_en,
    name_ar,
    position_ar,
    position_en,
    image,
  });

  if (res) {
    return res;
  } else return false;
};

export const deleteTeamMember = (teamMemberId) => {
  const res = api.delete(`api/admin/delete_team_member/${teamMemberId}`);

  if (res) {
    return res;
  } else return false;
};
