<template>
  <div class="add_project">
    <!-- Animated Loader -->
    <animated-loader :loading="isLoading" />

    <h4 class="section_title">Our projects</h4>

    <button class="single_tab">Add Project</button>

    <div class="content_wrapper">
      <form @submit.prevent="submitForm">
        <div class="english_inputs">
          <div class="form_row">
            <div>
              <div class="input_control upload_image">
                <label> Project Photo</label>
                <input
                  id="imagePath"
                  type="text"
                  class="disabled_input"
                  disabled
                  placeholder="Choose file"
                />
                <GrayButton text="Browse" size="small" browseBtn="en">
                  <input
                    @change="uploadImage"
                    id="imageInput"
                    type="file"
                    accept="image/*"
                  />
                </GrayButton>
              </div>
            </div>
            <div>
              <div class="input_control">
                <label>Project Name</label>
                <input
                  type="text"
                  placeholder="Enter name"
                  v-model="enProjectName"
                  @keypress="$checkInputCharacters(enProjectName, $event)"
                />
                <span class="hint">
                  {{ enProjectName.length }}/50 (Maximum 50 characters)
                </span>
              </div>
            </div>
          </div>

          <div class="textarea_control">
            <label>Section Description</label>
            <textarea
              placeholder="Enter section description"
              v-model="enProjectDescription"
              @keypress="$checkTextareaCharacters(enProjectDescription, $event)"
            ></textarea>
            <span class="hint">
              {{ enProjectDescription.length }}/450 (Maximum 450 characters)
            </span>
          </div>

          <div class="form_row">
            <div>
              <div class="input_control upload_image">
                <label> Project Gallery Photos</label>
                <input
                  id="galleryImagePath"
                  type="text"
                  class="disabled_input"
                  disabled
                  placeholder="Choose file/s"
                />
                <GrayButton text="Browse" size="small" browseBtn="en">
                  <input
                    @change="uploadgalleryImages"
                    id="galleryImageInput"
                    type="file"
                    accept="image/*"
                    multiple
                  />
                </GrayButton>
              </div>
              <span class="hint"> 5 photos maximum </span>
            </div>
            <div>
              <div class="gallery_images">
                <span v-if="uploadedProjectGalleryImages.length == 0"
                  >No photo/s choosed</span
                >
                <div
                  class="single_item"
                  v-for="(img, index) in uploadedProjectGalleryImages"
                  :key="(img, index)"
                >
                  <div class="img_wrapper">
                    <img :src="img" alt="" />
                  </div>
                  <div class="delete_image_icon" @click="deletePhoto(index)">
                    <img src="@/assets/icons/close_icon.svg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="line"></div>

        <div class="arabic_inputs">
          <div class="form_row">
            <div></div>
            <div>
              <div class="input_control">
                <label> اسم المشروع </label>
                <input
                  type="text"
                  placeholder=" أدخل عنوان"
                  dir="rtl"
                  v-model="arProjectName"
                  @keypress="$checkInputCharacters(arProjectName, $event)"
                />
                <span class="hint"
                  >(أقصى عدد من الأحرف {{ "50".toIndiaDigits() }}){{
                    String(arProjectName.length).toIndiaDigits()
                  }}/{{ "50".toIndiaDigits() }}
                </span>
              </div>
            </div>
          </div>

          <div class="textarea_control">
            <label>وصف المشروع</label>
            <textarea
              placeholder="ادخل وصف الخدمة"
              dir="rtl"
              v-model="arProjectDescription"
              @keypress="$checkTextareaCharacters(arProjectDescription, $event)"
            ></textarea>
            <span class="hint">
              {{ String(arProjectDescription.length).toIndiaDigits() }}/{{
                "450".toIndiaDigits()
              }}
              (أقصى عدد من الأحرف {{ "450".toIndiaDigits() }})
            </span>
          </div>
        </div>
        <div class="action_btns">
          <button
            type="button"
            class="cancel_btn"
            @click="$modal.show('cancelAddProjectModal')"
          >
            Cancel
          </button>
          <GrayButton text="Confirm" size="large" />
        </div>
      </form>
    </div>

    <!-- Cancel Add Project Modal -->
    <modal name="cancelAddProjectModal">
      <div class="modal_content">
        <div class="img_wrapper">
          <img src="@/assets/icons/warning_icon.svg" alt="" />
        </div>
        <div class="text_content">
          <h4>Cancel Add project process?</h4>
          <p>You will not continue this process</p>
        </div>
        <div class="action_btns">
          <button type="button" @click="$modal.hide('cancelAddProjectModal')">
            Keep adding
          </button>
          <button type="button" @click="cancelProccess">Yes, Cancel</button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import GrayButton from "../UI/GrayButton.vue";
// eslint-disable-next-line no-unused-vars
import { addProject } from "@/endPoints/projects";
import AnimatedLoader from "../UI/AnimatedLoader.vue";

export default {
  components: {
    GrayButton,
    AnimatedLoader,
  },

  data() {
    return {
      // Images
      uploadedProjectImage: "",
      fileList: [],
      uploadedImage: "",
      uploadedProjectGalleryImages: [],

      // Inputs
      enProjectName: "",
      enProjectDescription: "",
      arProjectName: "",
      arProjectDescription: "",

      categoryId: this.$route.params.id,

      isLoading: false,
    };
  },

  methods: {
    uploadImage() {
      const imageInput = document.getElementById("imageInput");
      const imagePath = document.getElementById("imagePath");
      imagePath.value = imageInput.files[0].name;

      const reader = new FileReader();
      reader.onload = (e) => {
        this.uploadedProjectImage = e.target.result;
      };

      reader.readAsDataURL(imageInput.files[0]);
    },

    uploadgalleryImages(e) {
      this.fileList = [...e.target.files];

      for (let i = 0; this.fileList.length >= i; i++) {
        if (this.fileList && this.fileList[i]) {
          const reader = new FileReader();
          reader.onload = (e) => {
            this.uploadedImage = e.target.result;
            this.uploadedProjectGalleryImages.push(this.uploadedImage);
          };
          reader.readAsDataURL(this.fileList[i]);
        }
      }
    },

    deletePhoto(index) {
      this.uploadedProjectGalleryImages.splice(index, 1);
    },

    async submitForm() {
      if (this.uploadedProjectImage == "") {
        this.$noty.warning("Please select project image");
      } else if (this.uploadedProjectGalleryImages.length > 5) {
        this.$noty.warning("You can't choose more than 5 photos");
      } else if (this.enProjectName == "") {
        this.$noty.warning("Please enter english project name");
      } else if (this.enProjectName.length > 50) {
        this.$noty.warning("Project name can't be more than 50 characters");
      } else if (this.enProjectDescription == "") {
        this.$noty.warning("Please enter english project description");
      } else if (this.enProjectDescription.length > 450) {
        this.$noty.warning(
          "Section description can't be more than 450 characters"
        );
      } else if (this.arProjectName == "") {
        this.$noty.warning("Please enter arabic project name");
      } else if (this.arProjectName.length > 50) {
        this.$noty.warning("Project name can't be more than 50 characters");
      } else if (this.arProjectDescription == "") {
        this.$noty.warning("Please enter arabic project description");
      } else if (this.arProjectDescription.length > 450) {
        this.$noty.warning(
          "Section description can't be more than 450 characters"
        );
      } else {
        this.isLoading = true;
        const res = await addProject(
          this.arProjectName,
          this.enProjectName,
          this.arProjectDescription,
          this.enProjectDescription,
          this.categoryId,
          this.uploadedProjectImage,
          this.uploadedProjectGalleryImages
        );
        if (res) {
          this.isLoading = false;
          this.$noty.success("Project has been added successfully");
          this.$router.push("/projects");
        }
      }
    },

    cancelProccess() {
      this.$router.push("/projects");
    },
  },

  mounted() {},
};
</script>

<style scoped>
.add_project .form_row:nth-child(3) {
  align-items: center;
}

.add_project .gallery_images {
  width: 100%;
  min-height: 114px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  padding: 25px 45px;
  border: 2px solid #4d4d4d;
  border-radius: 25px;
}

.add_project .gallery_images .single_item {
  position: relative;
}

.add_project .gallery_images .img_wrapper {
  width: 60px;
  height: 60px;
}

.add_project .gallery_images .img_wrapper img {
  width: 100%;
  height: 100%;
}

.add_project .gallery_images .single_item .delete_image_icon {
  position: absolute;
  top: -10px;
  right: -10px;
  cursor: pointer;
}
</style>
