<template>
  <div class="add_team_member">
    <!-- Animated Loader -->
    <animated-loader :loading="isLoading" />

    <h4 class="section_title">About Us/Our Team</h4>

    <button class="single_tab">Add Team Member</button>

    <div class="content_wrapper">
      <form @submit.prevent="submitForm">
        <div class="english_inputs">
          <div class="form_row">
            <div>
              <div class="input_control upload_image">
                <label> Member Photo </label>
                <input
                  id="imagePath"
                  type="text"
                  class="disabled_input"
                  disabled
                  placeholder="Choose file"
                />
                <GrayButton text="Browse" size="small" browseBtn="en">
                  <input
                    @change="uploadImage"
                    id="imageInput"
                    type="file"
                    accept="image/*"
                  />
                </GrayButton>
              </div>
            </div>
            <div>
              <div class="input_control">
                <label>Member Name</label>
                <input
                  type="text"
                  placeholder="Enter name"
                  v-model="enMemberName"
                  @keypress="$checkInputCharacters(enMemberName, $event)"
                />
                <span class="hint">
                  {{ enMemberName.length }}/50 (Maximum 50 characters)
                </span>
              </div>
            </div>
          </div>

          <div class="form_row">
            <div>
              <div class="input_control">
                <label>Member Position</label>
                <input
                  type="text"
                  placeholder="Enter position"
                  v-model="enMemberPosition"
                  @keypress="$checkInputCharacters(enMemberPosition, $event)"
                />
                <span class="hint">
                  {{ enMemberPosition.length }}/50 (Maximum 50 characters)
                </span>
              </div>
            </div>
            <div></div>
          </div>
        </div>

        <div class="line"></div>

        <div class="arabic_inputs">
          <div class="form_row">
            <div>
              <div class="input_control">
                <label> منصب العضو </label>
                <input
                  type="text"
                  placeholder="أدخل منصب العضو"
                  dir="rtl"
                  v-model="arMemberPosition"
                  @keypress="$checkInputCharacters(arMemberPosition, $event)"
                />
                <span class="hint"
                  >(أقصى عدد من الأحرف {{ "50".toIndiaDigits() }}){{
                    String(arMemberPosition.length).toIndiaDigits()
                  }}/{{ "50".toIndiaDigits() }}
                </span>
              </div>
            </div>
            <div>
              <div class="input_control">
                <label> اسم العضو </label>
                <input
                  type="text"
                  placeholder="أدخل اسم العضو"
                  dir="rtl"
                  v-model="arMemberName"
                  @keypress="$checkInputCharacters(arMemberName, $event)"
                />
                <span class="hint"
                  >(أقصى عدد من الأحرف {{ "50".toIndiaDigits() }}){{
                    String(arMemberName.length).toIndiaDigits()
                  }}/{{ "50".toIndiaDigits() }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="action_btns">
          <button
            type="button"
            class="cancel_btn"
            @click="$modal.show('cancelAddMemberModal')"
          >
            Cancel
          </button>
          <GrayButton text="Confirm" size="large" />
        </div>
      </form>
    </div>

    <!-- Cancel Modal -->
    <modal name="cancelAddMemberModal">
      <div class="modal_content">
        <div class="img_wrapper">
          <img src="@/assets/icons/warning_icon.svg" alt="" />
        </div>
        <div class="text_content">
          <h4>Cancel Add team member process?</h4>
          <p>You will not continue this process</p>
        </div>
        <div class="action_btns">
          <button type="button" @click="$modal.hide('cancelAddMemberModal')">
            Keep adding
          </button>
          <button type="button" @click="cancelAddClient">Yes, Cancel</button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import GrayButton from "../UI/GrayButton.vue";
import { AddTeamMember } from "@/endPoints/aboutUs";
import AnimatedLoader from "../UI/AnimatedLoader.vue";

export default {
  components: {
    GrayButton,
    AnimatedLoader,
  },

  data() {
    return {
      uploadedImage: "",
      enMemberName: "",
      enMemberPosition: "",
      arMemberName: "",
      arMemberPosition: "",

      isLoading: false,
    };
  },

  methods: {
    uploadImage() {
      const imageInput = document.getElementById("imageInput");
      const imagePath = document.getElementById("imagePath");
      const file = imageInput.files[0];
      imagePath.value = file.name;

      const reader = new FileReader();
      reader.onloadend = () => {
        this.uploadedImage = reader.result;
      };
      reader.readAsDataURL(file);
    },

    cancelAddClient() {
      this.$modal.hide("cancelAddMemberModal");
      this.$router.push("/about");
    },

    async submitForm() {
      if (this.uploadedImage == "") {
        this.$noty.warning("Please select member photo");
      } else if (this.enMemberName == "") {
        this.$noty.warning("Please enter english member name");
      } else if (this.enMemberName.length > 50) {
        this.$noty.warning("Member name can't be more than 50 characters");
      } else if (this.enMemberPosition == "") {
        this.$noty.warning("Please enter english member position");
      } else if (this.enMemberPosition.length > 50) {
        this.$noty.warning("Member position can't be more than 50 characters");
      } else if (this.arMemberName == "") {
        this.$noty.warning("Please enter arabic member name");
      } else if (this.arMemberName.length > 50) {
        this.$noty.warning("Member name can't be more than 50 characters");
      } else if (this.arMemberPosition == "") {
        this.$noty.warning("Please enter arabic member position");
      } else if (this.arMemberPosition.length > 50) {
        this.$noty.warning("Member position can't be more than 50 characters");
      } else {
        this.isLoading = true;
        const res = await AddTeamMember(
          this.enMemberName,
          this.arMemberName,
          this.arMemberPosition,
          this.enMemberPosition,
          this.uploadedImage
        );

        if (res.data) {
          this.isLoading = false;
          this.$noty.success("Team member has been added successfully");
          this.$router.push("/about");
        }
      }
    },
  },
};
</script>

<style scoped>
.add_team_member .action_btns {
  display: flex;
  gap: 30px;
  justify-content: flex-end;
}
</style>
