import ContactUs from "@/views/ContactUs";

const contactRoutes = [
  {
    path: "/contact",
    name: "ContactUs",
    component: ContactUs,
  },
];

export default contactRoutes;
