<template>
  <div>
    <h1>This is an service page</h1>
  </div>
</template>

<script>
export default {};
</script>

<style scoped></style>
