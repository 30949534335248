<template>
  <div>
    <ChoosedView />
  </div>
</template>

<script>
import ChoosedView from "@/views/ChoosedView";

export default {
  name: "App",
  components: {
    ChoosedView,
  },
};
</script>

<style>
/* Vue Noty Styles */
@import "vuejs-noty/dist/vuejs-noty.css";

/* Fonts */
@font-face {
  font-family: "Roboto";
  src: url("@/assets/fonts/Roboto/Roboto-Regular.eot");
  src: url("@/assets/fonts/Roboto/Roboto-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("@/assets/fonts/Roboto/Roboto-Regular.woff2") format("woff2"),
    url("@/assets/fonts/Roboto/Roboto-Regular.woff") format("woff"),
    url("@/assets/fonts/Roboto/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("@/assets/fonts/Poppins/Poppins-SemiBold.eot");
  src: url("@/assets/fonts/Poppins/Poppins-SemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("@/assets/fonts/Poppins/Poppins-SemiBold.woff2") format("woff2"),
    url("@/assets/fonts/Poppins/Poppins-SemiBold.woff") format("woff"),
    url("@/assets/fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

/*Start Scrollbar*/
::-webkit-scrollbar {
  width: 7px;
  background: transparent !important;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: transparent;
  color: transparent;
  background: transparent !important;
  border-radius: 15px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #707070;
  border-radius: 15px;
}

:root {
  scrollbar-color: grey transparent !important;
  scrollbar-width: none !important;
}
/* End Scrollbar */

/* Comman Styles */
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
  /* font-family: "Roboto", sans-serif; */
  background-color: #2d2c2c;
  color: #d3d3d3;
  /* overflow: hidden;
  width: 100vw; */
}

a {
  text-decoration: none;
  color: #d3d3d3;
}

/* Variables */
:root {
  --border_radius: 20px;
  --transition: all 0.5s ease;
}

/* General Styles */
input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"] {
  font-family: "Roboto", sans-serif;
  position: relative;
  width: 100%;
  height: 34px;
  border: 2px solid #4d4d4d;
  background-color: transparent;
  color: #d3d3d3;
  font-size: 14px;
  border-radius: var(--border_radius);
  padding: 0 23px;
  outline: 0;
}

input[type="text"]::placeholder,
input[type="email"]::placeholder,
input[type="password"]::placeholder,
input[type="number"] ::placeholder,
textarea::placeholder {
  font-size: 12px;
}

textarea {
  font-family: "Roboto", sans-serif;
  width: 100%;
  height: 190px;
  border: 2px solid #4d4d4d;
  background-color: transparent;
  color: #d3d3d3;
  font-size: 14px;
  border-radius: var(--border_radius);
  padding: 12px 23px 0;
  outline: 0;
  resize: none;
}

/* Comman use calsses */
.centred_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.content_wrapper {
  background-color: #333333;
  padding: 55px 60px 40px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
}

.english_inputs {
  padding-bottom: 45px;
}

.arabic_inputs {
  padding-top: 55px;
}

.line {
  width: 100%;
  /* height: 1px; */
  border-top: 2px solid #707070;
}

.form_row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 26px;
  gap: 30px;
}

.form_row > div {
  flex: 0.5;
}

.input_control {
  position: relative;
}

.textarea_control {
  margin-bottom: 60px;
}

.upload_image input[type="file"] {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
}

.input_control label,
.textarea_control label {
  display: block;
  font-size: 14px;
  padding: 0 8px;
  margin-bottom: 10px;
  font-family: "Roboto", sans-serif;
}

.arabic_inputs {
  margin-bottom: 50px;
}

.arabic_inputs .input_control label,
.arabic_inputs .textarea_control label {
  text-align: right;
}

.arabic_inputs .input_control input,
.arabic_inputs .textarea_control textarea {
  text-align: right;
}

.section_title {
  font-size: 16px;
  margin-bottom: 32px;
}

span.hint {
  display: block;
  padding-top: 8px;
  margin-left: 8px;
  font-size: 11px;
  font-family: "Roboto", sans-serif;
}

.arabic_inputs span.hint {
  text-align: right;
  margin-right: 8px;
  font-size: 12px;
}

.cancel_btn {
  width: 200px;
  height: 45px;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  position: relative;
  display: block;
  border: 0;
  outline: 0;
  background-color: transparent;
  color: #fff7f7;
  border: 1px solid #707070;
  border-radius: 20px;
  cursor: pointer;
}

.single_tab {
  border: 0;
  outline: 0;
  padding: 18px 35px;
  background-color: #333333;
  color: #d3d3d3;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  font-size: 16px;
}

.action_btns {
  display: flex;
  gap: 30px;
  justify-content: flex-end;
}

/* Modal Styles */
.vm--overlay {
  background: rgba(0, 0, 0, 0.5) !important;
}
.vm--modal {
  width: 350px !important;
  height: auto !important;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  border-radius: 25px !important;
  padding: 35px 0 25px 0 !important;
}

.vm--modal .img_wrapper {
  width: 80px;
  height: 70px;
  margin: 0 auto 30px;
}

.vm--modal .img_wrapper img {
  width: 100%;
  height: auto;
}

.vm--modal .text_content {
  text-align: center;
  margin-bottom: 55px;
}

.vm--modal .text_content h4 {
  font-size: 18px;
  color: #272626;
}

.vm--modal .text_content p {
  font-size: 12px;
  color: #707070;
  font-family: "Roboto", sans-serif;
}

.vm--modal .action_btns {
  display: flex;
  gap: 15px !important;
  justify-content: center !important;
}

.vm--modal .action_btns button {
  width: 145px;
  height: 30px;
  border-radius: 20px;
  outline: none;
  cursor: pointer;
}

.vm--modal .action_btns button:first-child {
  border: 1px solid #707070;
  background: #fff;
  color: #707070;
}

.vm--modal .action_btns button:last-child {
  border: 1px solid #707070;
  background: #707070;
  color: #fff;
}

/* Loader Transition Styles*/
.loader-enter,
.loader-leave-to {
  opacity: 0;
}

.loader-enter-active,
.loader-leave-active {
  transition: all 0.5s ease;
}
</style>
