<template>
    <div class="add_client">
      <!-- Animated Loader -->
      <animated-loader :loading="isLoading" />
  
      <h4 class="section_title">Home/Blogs</h4>
  
      <button class="single_tab">Add Blog</button>
  
      <div class="content_wrapper">
        <form @submit.prevent="submitForm">
          <div class="english_inputs">
            <div class="form_row">
              <div>
                <div class="input_control">
                  <label>Blog Title</label>
                  <input
                    type="text"
                    placeholder="Enter Client Name"
                    v-model="title_en"
                    @keypress="$checkInputCharacters(title_en, $event)"
                  />
                  <span class="hint">
                    {{ title_en.length }}/50 (Maximum 50 characters)
                  </span>
                </div>
              </div>
            </div>

            <div class="from_row">
              <div class="textarea_control">
              <label>Your Blog</label>
              <textarea
                placeholder="Write your blog here"
                v-model="enSectionDescription"
                @keypress="$checkTextareaCharacters(enSectionDescription, $event)"
              ></textarea>
              <span class="hint">
                {{ enSectionDescription.length }}/450 (Maximum 450 characters)
              </span>
            </div>
            </div>

            <div class="form_row">
              <div>
                <div class="input_control upload_image">
                  <label> Client Photo </label>
                  <input
                    id="imagePath"
                    type="text"
                    class="disabled_input"
                    disabled
                    placeholder="Choose file"
                  />
                  <GrayButton text="Browse" size="small" browseBtn="en">
                    <input
                      @change="uploadImage"
                      id="fileInput"
                      type="file"
                      accept="image/"
                    />
                  </GrayButton>
                </div>
              </div>
            </div>
          </div>
  
          <div class="line"></div>
  
          <div class="arabic_inputs">
            <div class="form_row">
              <div></div>
              <div>
                <div class="input_control">
                  <label>عنوان التدوينه</label>
                  <input
                    type="text"
                    placeholder="ادخل عنوان التدوينه"
                    v-model="title_ar"
                    @keypress="$checkInputCharacters(title_ar, $event)"
                  />
                  <span class="hint"
                    >(أقصى عدد من الأحرف {{ "50".toIndiaDigits() }}){{
                      String(title_ar.length).toIndiaDigits()
                    }}/{{ "50".toIndiaDigits() }}
                  </span>
                </div>
              </div>
            </div>


            <div class="form_row">
              <div class="textarea_control">
              <label>وصف التدوينة</label>
              <textarea
                placeholder="ادخل وصف التدوينة"
                dir="rtl"
                v-model="arSectionDescription"
                @keypress="$checkTextareaCharacters(arSectionDescription, $event)"
              ></textarea>
              <span class="hint">
                {{ String(arSectionDescription.length).toIndiaDigits() }}/{{
                  "450".toIndiaDigits()
                }}
                (أقصى عدد من الأحرف {{ "450".toIndiaDigits() }})
              </span>
            </div>
            </div>
          </div>
  
          <div class="action_btns">
            <button
              type="button"
              class="cancel_btn"
              @click="$modal.show('cancelAddCleintModal')"
            >
              Cancel
            </button>
            <GrayButton text="Confirm" size="large" />
          </div>
        </form>
      </div>
  
      <!-- Cancel Modal -->
      <modal name="cancelAddCleintModal">
        <div class="modal_content">
          <div class="img_wrapper">
            <img src="@/assets/icons/warning_icon.svg" alt="" />
          </div>
          <div class="text_content">
            <h4>Cancel Add client process?</h4>
            <p>You will not continue this process</p>
          </div>
          <div class="action_btns">
            <button type="button" @click="$modal.hide('cancelAddCleintModal')">
              Keep adding
            </button>
            <button type="button" @click="cancelAddClient">Yes, Cancel</button>
          </div>
        </div>
      </modal>
    </div>
  </template>
  
  <script>
  import GrayButton from "../UI/GrayButton.vue";
  import { AddBlog } from "@/endPoints/home";
  import AnimatedLoader from "../UI/AnimatedLoader.vue";
  
  export default {
    components: {
      GrayButton,
      AnimatedLoader,
    },
  
    data() {
      return {
        //  Upload logo
        uploadedImages: "",
  
        // Input
        title_en: "",
        title_ar: "",

        enSectionDescription:"",
        arSectionDescription:"",
  
        isLoading: false,
      };
    },
  
    methods: {
      //  Upload image
      uploadImage() {
        const fileInput = document.getElementById("fileInput");
        const imagePath = document.getElementById("imagePath");
        const file = fileInput.files[0];
        imagePath.value = file.name;
  
        const reader = new FileReader();
  
        reader.onloadend = () => {
          this.uploadedImages = reader.result;
        };
  
        reader.readAsDataURL(file);
      },
  
      // Submit form
      async submitForm() {
        if (this.uploadedImages == "") {
          this.$noty.warning("Please select blog image");
        } else if (this.title_en == "") {
          this.$noty.warning("Please enter english blog title");
        } else if (this.title_ar == "") {
          this.$noty.warning("Please enter arabic blog title");
        }else if (this.enSectionDescription == "") {
          this.$noty.warning("Please enter blog description");
        }else if (this.arSectionDescription == "") {
          this.$noty.warning("Please enter arabic blog description");
        } else if (this.title_en.length > 50) {
          this.$noty.warning("blog title can't be more than 50 characters");
        } else if (this.title_ar.length > 50) {
          this.$noty.warning("blog title can't be more than 50 characters");
        }else if (this.enSectionDescription.length > 450) {
          this.$noty.warning("blog description can't be more than 450 characters");
        }else if (this.arSectionDescription.length > 450) {
          this.$noty.warning("blog description can't be more than 450 characters");
        } else {
          let imagesBlog = []
          imagesBlog.push(this.uploadedImages)
          this.isLoading = true;
          const res = await AddBlog(
            this.title_en,
            this.title_ar,
            this.enSectionDescription,
            this.arSectionDescription,
            imagesBlog
          );
  
          if (res) {
            this.isLoading = false;
            this.$noty.success("Blog has been added successfully");
            this.$router.push("/");
          }
        }
      },
  
      cancelAddClient() {
        this.$router.push("/");
      },
    },
  };
  </script>
  
  <style scoped>
  .add_client .action_btns {
    display: flex;
    gap: 30px;
    justify-content: flex-end;
  }
  </style>
  