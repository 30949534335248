<template>
  <div class="add_category">
    <!-- Animated Loader -->
    <animated-loader :loading="isLoading" />

    <h4 class="section_title">Our projects/Add new category</h4>

    <button class="single_tab">Add new category</button>

    <div class="content_wrapper">
      <form @submit.prevent="submitForm">
        <div class="english_inputs">
          <div class="form_row">
            <div>
              <div class="input_control">
                <label>Category Name</label>
                <input
                  type="text"
                  placeholder="Enter name"
                  v-model="enCategoryName"
                  @keypress="$checkInputCharacters(enCategoryName, $event)"
                />
                <span class="hint">
                  {{ enCategoryName.length }}/50 (Maximum 50 characters)
                </span>
              </div>
            </div>
            <div></div>
          </div>
        </div>

        <div class="line"></div>

        <div class="arabic_inputs">
          <div class="form_row">
            <div></div>
            <div>
              <div class="input_control">
                <label>اسم التصنيف</label>
                <input
                  type="text"
                  placeholder="أدخل اسم التصنيف"
                  v-model="arCategoryName"
                  @keypress="$checkInputCharacters(arCategoryName, $event)"
                />
                <span class="hint"
                  >(أقصى عدد من الأحرف {{ "50".toIndiaDigits() }}){{
                    String(arCategoryName.length).toIndiaDigits()
                  }}/{{ "50".toIndiaDigits() }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="action_btns">
          <button
            type="button"
            class="cancel_btn"
            @click="$modal.show('cancelCategoryModal')"
          >
            Cancel
          </button>
          <GrayButton text="Confirm" size="large" />
        </div>
      </form>
    </div>

    <!-- Cancel Modal -->
    <modal name="cancelCategoryModal">
      <div class="modal_content">
        <div class="img_wrapper">
          <img src="@/assets/icons/warning_icon.svg" alt="" />
        </div>
        <div class="text_content">
          <h4>Cancel Add category process?</h4>
          <p>You will not continue this process</p>
        </div>
        <div class="action_btns">
          <button type="button" @click="$modal.hide('cancelCategoryModal')">
            Keep adding
          </button>
          <button type="button" @click="cancelProccess">Yes, Cancel</button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import GrayButton from "../UI/GrayButton.vue";
import { createCategory } from "@/endPoints/projects";
import AnimatedLoader from "../UI/AnimatedLoader.vue";

export default {
  components: { GrayButton, AnimatedLoader },

  data() {
    return {
      // Inputs
      enCategoryName: "",
      arCategoryName: "",

      isLoading: false,
    };
  },

  methods: {
    async submitForm() {
      if (this.enCategoryName == "") {
        this.$noty.warning("Please enter english category name");
      } else if (this.enCategoryName.length > 50) {
        this.$noty.warning("Category name can't be more than 50 characters");
      } else if (this.arCategoryName == "") {
        this.$noty.warning("Please enter arabic category name");
      } else if (this.arCategoryName.length > 50) {
        this.$noty.warning("Category name can't be more than 50 characters");
      } else {
        this.isLoading = true;
        const res = await createCategory(
          this.arCategoryName,
          this.enCategoryName
        );

        if (res.data) {
          this.isLoading = false;
          this.$noty.success("Category has been add successfully");
          this.$router.push("/projects");
        }
      }
    },

    cancelProccess() {
      this.$router.push("/projects");
    },
  },
};
</script>

<style scoped></style>
