<template>
  <div class="edit_team_member">
    <!-- Animated Loader -->
    <animated-loader :loading="isLoading" />

    <h4 class="section_title">About Us/Our Team</h4>

    <button class="single_tab">Edit Team Member</button>

    <div class="content_wrapper">
      <form @submit.prevent="submitForm">
        <div class="english_inputs">
          <div class="icons">
            <div class="team_member_image">
              <div class="img_wrapper">
                <img :src="image" alt="" />
              </div>
              <span>{{ enMemberName }}</span>
              <span>{{ enMemberPosition }}</span>
            </div>
            <button
              type="button"
              class="delete_icon"
              @click="$modal.show('deleteMemberModal')"
            >
              <img src="@/assets/icons/delete_icon.svg" alt="" />
            </button>
          </div>
          <div class="form_row">
            <div>
              <div class="input_control upload_image">
                <label> Member Photo </label>
                <input
                  id="imagePath"
                  type="text"
                  class="disabled_input"
                  disabled
                  placeholder="Choose file"
                />
                <GrayButton text="Browse" size="small" browseBtn="en">
                  <input
                    id="imageInput"
                    @change="uploadImage"
                    type="file"
                    accept="image/*"
                  />
                </GrayButton>
              </div>
            </div>
            <div>
              <div class="input_control">
                <label>Member Name</label>
                <input
                  type="text"
                  placeholder="Enter member name"
                  v-model="enMemberName"
                  @keypress="$checkInputCharacters(enMemberName, $event)"
                />
                <span class="hint">
                  {{ enMemberName.length }}/50 (Maximum 50 characters)
                </span>
              </div>
            </div>
          </div>
          <div class="form_row">
            <div>
              <div class="input_control">
                <label>Member Position</label>
                <input
                  type="text"
                  placeholder="Enter member position"
                  v-model="enMemberPosition"
                  @keypress="$checkInputCharacters(enMemberPosition, $event)"
                />
                <span class="hint">
                  {{ enMemberPosition.length }}/50 (Maximum 50 characters)
                </span>
              </div>
            </div>
            <div></div>
          </div>
        </div>

        <div class="line"></div>

        <div class="arabic_inputs">
          <div class="form_row">
            <div>
              <div class="input_control">
                <label> منصب العضو</label>
                <input
                  type="text"
                  placeholder="أدخل منصب العضو"
                  dir="rtl"
                  v-model="arMemberPosition"
                  @keypress="$checkInputCharacters(arMemberPosition, $event)"
                />
                <span class="hint"
                  >(أقصى عدد من الأحرف {{ "50".toIndiaDigits() }}){{
                    String(arMemberPosition.length).toIndiaDigits()
                  }}/{{ "50".toIndiaDigits() }}
                </span>
              </div>
            </div>
            <div>
              <div class="input_control">
                <label> اسم العضو</label>
                <input
                  type="text"
                  placeholder="أدخل اسم العضو"
                  dir="rtl"
                  v-model="arMemberName"
                  @keypress="$checkInputCharacters(arMemberName, $event)"
                />
                <span class="hint"
                  >(أقصى عدد من الأحرف {{ "50".toIndiaDigits() }}){{
                    String(arMemberName.length).toIndiaDigits()
                  }}/{{ "50".toIndiaDigits() }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <GrayButton text="Save&Submit" size="large" position="right" />
      </form>
    </div>

    <!-- Delete Modal -->
    <modal name="deleteMemberModal">
      <div class="modal_content">
        <div class="img_wrapper">
          <img src="@/assets/icons/warning_icon.svg" alt="" />
        </div>
        <div class="text_content">
          <h4>Delete This Team Member?</h4>
          <p>You will not be able to recover it</p>
        </div>
        <div class="action_btns">
          <button type="button" @click="$modal.hide('deleteMemberModal')">
            Cancel
          </button>
          <button type="button" @click="deleteTeamMember">
            Yes, Delete it
          </button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import GrayButton from "@/components/UI/GrayButton.vue";
import {
  getSingleTeamMember,
  editTeamMember,
  deleteTeamMember,
} from "@/endPoints/aboutUs";
import AnimatedLoader from "../UI/AnimatedLoader.vue";

export default {
  components: {
    GrayButton,
    AnimatedLoader,
  },

  data() {
    return {
      // Image
      uploadedImage: "",
      image: "",

      // Inputs
      enMemberName: "",
      enMemberPosition: "",
      arMemberName: "",
      arMemberPosition: "",

      teamMemberId: this.$route.params.id,

      isLoading: false,
    };
  },

  methods: {
    uploadImage(e) {
      const imageInput = document.getElementById("imageInput");
      const imagePath = document.getElementById("imagePath");
      imagePath.value = imageInput.value.substring(12);
      const file = e.target.files[0];

      const reader = new FileReader();
      reader.onloadend = () => {
        this.uploadedImage = reader.result;
      };

      reader.readAsDataURL(file);
    },

    async submitForm() {
      if (this.enMemberName == "") {
        this.$noty.warning("Please enter english member name");
      } else if (this.enMemberName.length > 50) {
        this.$noty.warning("Member name can't be more than 50 characters");
      } else if (this.enMemberPosition == "") {
        this.$noty.warning("Please enter english member position");
      } else if (this.enMemberPosition.length > 50) {
        this.$noty.warning("Member position can't be more than 50 characters");
      } else if (this.arMemberName == "") {
        this.$noty.warning("Please enter arabic member name");
      } else if (this.arMemberName.length > 50) {
        this.$noty.warning("Member name can't be more than 50 characters");
      } else if (this.arMemberPosition == "") {
        this.$noty.warning("Please enter arabic member position");
      } else if (this.arMemberPosition.length > 50) {
        this.$noty.warning("Member position can't be more than 50 characters");
      } else {
        this.isLoading = true;
        const res = await editTeamMember(
          this.teamMemberId,
          this.enMemberName,
          this.arMemberName,
          this.enMemberPosition,
          this.arMemberPosition,
          this.uploadedImage
        );

        if (res) {
          this.isLoading = false;
          this.$noty.success("Team member has been edit successfully");
          this.$router.push("/about");
        }
      }
    },

    async getTeamMember() {
      this.isLoading = true;
      const res = await getSingleTeamMember(this.teamMemberId);

      if (res) {
        this.isLoading = false;
        const data = res.data.data;

        this.image = data.image;
        this.enMemberName = data.name_en;
        this.enMemberPosition = data.position_en;
        this.arMemberName = data.name_ar;
        this.arMemberPosition = data.position_ar;
      }
    },

    async deleteTeamMember() {
      this.isLoading = true;
      const res = await deleteTeamMember(this.teamMemberId);

      if (res) {
        this.isLoading = false;
        this.$noty.success("Team member deleted successfully");
        this.$router.push("/about");
      }
    },
  },

  mounted() {
    this.getTeamMember();
  },
};
</script>

<style scoped>
.edit_team_member .action_btns {
  display: flex;
  gap: 30px;
  justify-content: flex-end;
}

.edit_team_member .icons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.edit_team_member .arabic_inputs .icons {
  justify-content: flex-end;
}

.edit_team_member .icons .team_member_image {
  width: 180px;
  height: 162px;
  padding: 10px 0;
  border: 3px solid #2d2c2c;
  border-radius: 20px;
}

.edit_team_member .icons .team_member_image .img_wrapper {
  width: 75px;
  height: 75px;
  margin: 10px auto 12px;
  border-radius: 15px;
  overflow: hidden;
}

.edit_team_member .icons .team_member_image .img_wrapper img {
  width: 100%;
  height: auto;
}

.edit_team_member .icons .team_member_image span {
  display: block;
  font-family: "Roboto", sans-serif;
  font-size: 11px;
  text-align: center;
}

.edit_team_member .icons .team_member_image span:first-of-type {
  font-size: 14px;
  margin-bottom: 3px;
}

.edit_team_member .icons .delete_icon {
  background-color: transparent;
  border: 0;
  outline: 0;
  align-self: flex-start;
  cursor: pointer;
}

.edit_team_member .icons .delete_icon img {
  width: 35px;
}

.edit_team_member .form_row div.description_photo {
  display: flex;
  justify-content: flex-start;
}

.edit_team_member .description_photo .img_wrapper {
  width: 75px;
  height: 75px;
  margin: auto;
}

.edit_team_member .description_photo .img_wrapper img {
  width: 100%;
  height: auto;
}

.edit_team_member .description_photo span {
  display: block;
  font-family: "Roboto", sans-serif;
  font-size: 9px;
  text-align: center;
}
</style>
