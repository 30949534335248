<template>
  <div id="about">
    <h4 class="section_title">About Us/{{ theSelectedView }}</h4>

    <!-- Tabs -->
    <NavigationTabs
      :tabs="tabs"
      :selectView="selectView"
      :theSelectedView="theSelectedView"
    />

    <!-- About Content -->
    <div class="content_wrapper">
      <!-- About Us Section -->
      <AboutUsSection v-if="theSelectedView == 'About Us'" />
      <!-- Our Team Section -->
      <OurTeamSection v-if="theSelectedView == 'Our Team'" />
    </div>
  </div>
</template>

<script>
import NavigationTabs from "@/components/UI/NavigationTabs.vue";
import AboutUsSection from "@/components/about/AboutUsSection.vue";
import OurTeamSection from "@/components/about/OurTeamSection.vue";

export default {
  components: {
    NavigationTabs,
    AboutUsSection,
    OurTeamSection,
  },

  data() {
    return {
      /* Tabs */
      tabs: ["About Us", "Our Team"],
      theSelectedView: "",
    };
  },

  methods: {
    /* Tabs */
    selectView(value) {
      this.theSelectedView = value;
      localStorage.setItem("aboutSelectedView", this.theSelectedView);
    },
  },

  mounted() {
    /* Tabs */
    this.theSelectedView = localStorage.getItem("aboutSelectedView")
      ? localStorage.getItem("aboutSelectedView")
      : "About Us";
  },
};
</script>

<style scoped></style>
