<template>
  <div class="edit_client">
    <!-- Animated Loader -->
    <animated-loader :loading="isLoading" />

    <h4 class="section_title">Home/Clients/{{ enClientName }}</h4>

    <button class="single_tab">{{ enClientName }}</button>

    <div class="content_wrapper">
      <form @submit.prevent="submitForm">
        <div class="icons">
          <div class="client_image">
            <div class="img_wrapper">
              <img :src="image" alt="" />
            </div>
            <span>{{ enClientName }}</span>
          </div>
          <button
            type="button"
            class="delete_icon"
            @click="$modal.show('deleteClienteModal')"
          >
            <img src="@/assets/icons/delete_icon.svg" alt="" />
          </button>
        </div>
        <div class="english_input">
          <div class="form_row">
            <div>
              <div class="input_control upload_image">
                <label> Client Photo </label>
                <input
                  id="imagePath"
                  type="text"
                  class="disabled_input"
                  disabled
                  placeholder="Choose file"
                />
                <GrayButton text="Browse" size="small" browseBtn="en">
                  <input type="file" accept="image/*" @change="uploadImage" />
                </GrayButton>
              </div>
            </div>
            <div>
              <div class="input_control">
                <label>Client Name</label>
                <input
                  id="imageInput"
                  type="text"
                  placeholder="Enter Client Name"
                  v-model="enClientName"
                  @keypress="$checkInputCharacters(enClientName, $event)"
                />
                <span class="hint">
                  {{ enClientName.length }}/50 (Maximum 50 characters)
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="arabic_inputs">
          <div class="form_row">
            <div></div>
            <div>
              <div class="input_control">
                <label>اسم العميل</label>
                <input
                  type="text"
                  placeholder="ادخل اسم العميل"
                  v-model="arClientName"
                  @keypress="$checkInputCharacters(arClientName, $event)"
                />
                <span class="hint"
                  >(أقصى عدد من الأحرف {{ "50".toIndiaDigits() }}){{
                    String(arClientName.length).toIndiaDigits()
                  }}/{{ "50".toIndiaDigits() }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="action_btns">
          <router-link to="/">
            <button class="cancel_btn">Cancel</button>
          </router-link>
          <GrayButton text="Save&Submit" size="large" />
        </div>
      </form>
    </div>

    <!-- Delete Modal -->
    <modal name="deleteClienteModal">
      <div class="modal_content">
        <div class="img_wrapper">
          <img src="@/assets/icons/warning_icon.svg" alt="" />
        </div>
        <div class="text_content">
          <h4>Delete This Client?</h4>
          <p>You will not be able to recover it</p>
        </div>
        <div class="action_btns">
          <button @click="$modal.hide('deleteClienteModal')">Cancel</button>
          <button @click="deleteClient">Yes, Delete it</button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import GrayButton from "@/components/UI/GrayButton.vue";
import { getSingleClient, updateClient, deleteClient } from "@/endPoints/home";
import AnimatedLoader from "../UI/AnimatedLoader.vue";

export default {
  components: {
    GrayButton,
    AnimatedLoader,
  },

  data() {
    return {
      uploadedImage: "",
      image: "",
      enClientName: "",
      arClientName: "",

      clientId: this.$route.params.id,

      isLoading: false,
    };
  },

  methods: {
    uploadImage(e) {
      const imagePath = document.getElementById("imagePath");
      const file = e.target.files[0];
      imagePath.value = file.name;
      const reader = new FileReader();
      reader.onloadend = () => {
        this.uploadedImage = reader.result;
      };
      reader.readAsDataURL(file);
    },

    async submitForm() {
      if (this.uploadedImage == "") {
        this.$noty.warning("Please select client image");
      } else if (this.enClientName == "") {
        this.$noty.warning("Please enter client english name");
      } else if (this.arClientName == "") {
        this.$noty.warning("Please enter client arabic name");
      } else if (this.enClientName.length > 50) {
        this.$noty.warning("Client name can't be more than 50 characters");
      } else if (this.arClientName.length > 50) {
        this.$noty.warning("Client name can't be more than 50 characters");
      } else {
        this.isLoading = true;
        const res = await updateClient(
          this.clientId,
          this.enClientName,
          this.arClientName,
          this.uploadedImage
        );

        if (res) {
          this.isLoading = false;
          this.$noty.success("Client has been edit successfully");
          this.$router.push("/");
        }
      }
    },

    async getClient() {
      this.isLoading = true;
      const res = await getSingleClient(this.clientId);
      const data = res.data.data;

      if (res) {
        this.isLoading = false;
        this.image = data.image;
        this.enClientName = data.name_en;
        this.arClientName = data.name_ar;
      }
    },

    async deleteClient() {
      this.isLoading = true;
      const res = await deleteClient(this.clientId);
      if (res) {
        this.isLoading = false;
        this.$noty.success("Client has been deleted successfully");
        this.$router.push("/");
      }
    },
  },

  mounted() {
    this.getClient();
  },
};
</script>

<style scoped>
.edit_client .action_btns {
  display: flex;
  gap: 30px;
  justify-content: flex-end;
}

.edit_client .icons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.edit_client .icons .client_image {
  width: 135px;
  height: 100px;
  padding: 13px 0 12px;
  border: 3px solid #2d2c2c;
  border-radius: 20px;
}

.edit_client .icons .client_image .img_wrapper {
  width: 45px;
  height: 45px;
  margin: 0 auto 15px;
}

.edit_client .icons .client_image .img_wrapper img {
  width: 100%;
  height: auto;
}

.edit_client .icons .client_image span,
.edit_client .description_photo span {
  display: block;
  font-family: "Roboto", sans-serif;
  font-size: 9px;
  text-align: center;
}

.edit_client .icons .delete_icon {
  background-color: transparent;
  border: 0;
  outline: 0;
  align-self: flex-start;
  cursor: pointer;
}

.edit_client .icons .delete_icon img {
  width: 35px;
}

.edit_client .form_row div.description_photo {
  display: flex;
  justify-content: flex-start;
}

.edit_client .description_photo .img_wrapper {
  width: 75px;
  height: 75px;
  margin: auto;
}

.edit_client .description_photo .img_wrapper img {
  width: 100%;
  height: auto;
}

.edit_client .description_photo span {
  display: block;
  font-family: "Roboto", sans-serif;
  font-size: 9px;
  text-align: center;
}
</style>
