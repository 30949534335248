import HomeView from "@/views/HomeView";
import AddService from "@/components/home/AddService";
import EditService from "@/components/home/EditService";
import AddClient from "@/components/home/AddClient";
import EditClient from "@/components/home/EditClient";
import AddBlog from "@/components/home/AddBlog";

const homeRoutes = [
  {
    path: "/",
    name: "HomeView",
    component: HomeView,
  },
  {
    path: "/add-service",
    name: "AddService",
    component: AddService,
  },
  {
    path: "/edit-service/:id",
    name: "EditService",
    component: EditService,
  },
  {
    path: "/add-client",
    name: "AddClient",
    component: AddClient,
  },
  {
    path: "/edit-client/:id",
    name: "EditClient",
    component: EditClient,
  },
  {
    path: "/add-blog",
    name: "AddBlog",
    component: AddBlog,
  },

  
];

export default homeRoutes;
