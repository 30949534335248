import ServicesView from "@/views/ServicesView";

const servicesRoutes = [
  {
    path: "/services",
    name: "ServicesView",
    component: ServicesView,
  },
];

export default servicesRoutes;
