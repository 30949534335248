<template>
    <div class="clients_wrapper">
      <!-- Animated Loader -->
      <animated-loader :loading="isLoading" />
  
      <!-- clients section form -->
      <!-- <form @submit.prevent="submitForm">
        <div class="english_inputs">
          <div class="textarea_control">
            <label>Section Description</label>
            <textarea
              placeholder="Enter section description"
              v-model="enSectionDescription"
              @keypress="$checkTextareaCharacters(enSectionDescription, $event)"
            ></textarea>
            <span class="hint">
              {{ enSectionDescription.length }}/450 (Maximum 450 characters)
            </span>
          </div>
        </div>
  
        <div class="line"></div>
  
        <div class="arabic_inputs">
          <div class="textarea_control">
            <label>وصف القسم</label>
            <textarea
              placeholder="ادخل وصف القسم"
              dir="rtl"
              v-model="arSectionDescription"
              @keypress="$checkTextareaCharacters(arSectionDescription, $event)"
            ></textarea>
            <span class="hint">
              {{ String(arSectionDescription.length).toIndiaDigits() }}/{{
                "450".toIndiaDigits()
              }}
              (أقصى عدد من الأحرف {{ "450".toIndiaDigits() }})
            </span>
          </div>
        </div>
  
        <div class="action_btns">
          <GrayButton text="Save&Submit" size="large" position="right" />
        </div>
      </form> -->
  
      
      <!-- Cleints -->
      <div class="clients">
        <router-link
          :to="'#'"
          v-for="client in allClients"
          :key="client.id"
        >
          <div class="single_client">
            <div class="img_wrapper">
              <img :src="client.images[0].image" alt="" />
              <span>{{ client.article }}</span>
            </div>
          </div>
          <div class="deleteIcon" @click="openModalDeleteBlog(client.id)">
            <img src="@/assets/icons/delete_icon.svg" alt="delete"/>
          </div>
        </router-link>
  
        <router-link to="/add-blog">
          <div class="single_client add_client">
            <div class="img_wrapper">
              <img src="@/assets/icons/upload_icon.svg" alt="" />
              <span>Add Blogs</span>
            </div>
          </div>
        </router-link>
      </div>
      
      <router-link to="/add-blog">
        <GrayButton text="Add Blog" position="right" size="large" />
      </router-link>
      <DeleteModalBlog :modalData="deleteBlogModalData" />
    </div>
  </template>
  
  <script>
  import GrayButton from "../UI/GrayButton.vue";
  import DeleteModalBlog from "@/components/UI/DeleteModalBlog.vue";
  import { getAllBlogs, deleteBlog } from "@/endPoints/home";
  import AnimatedLoader from "../UI/AnimatedLoader.vue";
  
  export default {
    components: {
      GrayButton,
      AnimatedLoader,
      DeleteModalBlog
    },
  
    data() {
      return {
        enSectionDescription: "",
        arSectionDescription: "",
  
        allClients: [],
  
        isLoading: false,
        deleteBlogModalData: {
          question: "Do you want to delete blog?",
          warnning: "The blog will be deleted",
          cancelBtnText: "Cancel",
          actionBtnText: "Yes",
          actionFunc: this.deleteBlogItem,
        },

        blogId:""
      };
    },
  
    methods: {
      // async submitForm() {
      //   if (this.enSectionDescription == "") {
      //     this.$noty.warning("Please enter english section description");
      //   } else if (this.enSectionDescription.length > 450) {
      //     this.$noty.warning(
      //       "Section description can't be more than 450 characters"
      //     );
      //   } else if (this.arSectionDescription == "") {
      //     this.$noty.warning("Please enter arabic section description");
      //   } else if (this.arSectionDescription.length > 450) {
      //     this.$noty.warning(
      //       "Section description can't be more than 450 characters"
      //     );
      //   } else {
      //     this.isLoading = true;
      //     const res = await sendClientsDescription(
      //       this.arSectionDescription,
      //       this.enSectionDescription
      //     );
      //     if (res.data) {
      //       this.isLoading = false;
      //       this.$noty.success("Description has been updated successfully");
      //     }
      //   }
      // },
  
      // async getSectionDescription() {
      //   this.isLoading = true;
      //   const res = await getClientsDescription();
      //   const data = res.data.data;
  
      //   if (res) {
      //     this.isLoading = false;
      //     this.arSectionDescription = data.brief_ar;
      //     this.enSectionDescription = data.brief_en;
      //   }
      // },
  
      async getAllClients() {
        this.isLoading = true;
        const res = await getAllBlogs();
        if (res) {
          this.isLoading = false;
          this.allClients = res.data.data;
        }
      },

      openModalDeleteBlog(blogId) {
        this.blogId = blogId
        this.$modal.show("warningDeleteModal");
      },

      async deleteBlogItem() {
        const res = await deleteBlog(this.blogId)
        if(res){
          this.getAllClients()
          this.$modal.hide("warningDeleteModal");
        }
      }
    },
  
    mounted() {
      // this.getSectionDescription();
      this.getAllClients();
    },
  };
  </script>
  
  <style scoped>
  .clients {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    gap: 40px;
    margin-bottom: 60px;
  }
  
  .clients a{
    position:relative;
  }
    
  .clients_wrapper form {
    margin-bottom: 45px;
  }
  
  .clients_wrapper .textarea_control {
    margin-bottom: 0px !important;
  }
  
  .clients .single_client {
    height: 150px;
    border: 3px solid #2d2c2c;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .clients .img_wrapper {
    text-align: center;
  }
  
  .clients .img_wrapper img {
    width: 55px;
    height: 55px;
    margin-bottom: 20px;
  }
  
  .clients .single_client span {
    display: block;
    font-size: 11px;
    text-align: center;
    font-family: "Roboto", sans-serif;
  }
  
  .clients .add_client span {
    color: #494949;
  }

  .deleteIcon{
    position:absolute;
    top: 14px;
    right: 7px;
    width: 10%;
    height: auto;
  }
  .deleteIcon img{
    width:100%;
  }
  </style>
  