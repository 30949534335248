/* eslint-disable no-unused-vars */
import Vue from "vue";
import { headerConfig } from "./app.config";

Vue.prototype.$checkInputCharacters = function (text, event) {
  if (text.length > 49) {
    this.$noty.warning("Text can't be more than 50 characters");
    event.target.value = text.substring(0, 49);
  }
};

Vue.prototype.$checkTextareaCharacters = function (text, event) {
  if (text.length > 449) {
    this.$noty.warning("Text can't be more than 450 characters");
    event.target.value = text.substring(0, 449);
  }
};

String.prototype.toIndiaDigits = function () {
  const numbers = ["۰", "۱", "۲", "۳", "٤", "۵", "٦", "۷", "۸", "۹"];
  return this.replace(/[0-9]/g, function (w) {
    return numbers[+w];
  });
};

// Axios Helpers
export const getHeaderConfig = () => {
  return localStorage.getItem(headerConfig)
    ? JSON.parse(localStorage.getItem(headerConfig))
    : {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
};

export const setHeaderConfig = (header) => {
  localStorage.setItem(headerConfig, JSON.stringify(header));
};

export const getAuthStatus = () => {
  return localStorage.getItem(headerConfig) ? true : false;
};

export const clearAuth = () => {
  localStorage.removeItem(headerConfig);
};
