<template>
  <div class="about_us">
    <!-- Animated Loader -->
    <animated-loader :loading="isLoading" />

    <form @submit.prevent="submitForm">
      <div class="english_inputs">
        <div class="textarea_control">
          <label>Section Description</label>
          <textarea
            placeholder="Enter section description"
            v-model="enSectionDescription"
            @keypress="$checkTextareaCharacters(enSectionDescription, $event)"
          ></textarea>
          <span class="hint">
            {{ enSectionDescription.length }}/450 (Maximum 450 characters)
          </span>
        </div>
      </div>

      <div class="line"></div>

      <div class="arabic_inputs">
        <div class="textarea_control">
          <label>وصف القسم</label>
          <textarea
            placeholder="ادخل وصف القسم"
            dir="rtl"
            v-model="arSectionDescription"
            @keypress="$checkTextareaCharacters(arSectionDescription, $event)"
          ></textarea>
          <span class="hint">
            {{ String(arSectionDescription.length).toIndiaDigits() }}/{{
              "450".toIndiaDigits()
            }}
            (أقصى عدد من الأحرف {{ "450".toIndiaDigits() }})
          </span>
        </div>
      </div>
      <GrayButton text="Save&Submit" size="large" position="right" />
    </form>
  </div>
</template>

<script>
import GrayButton from "../UI/GrayButton.vue";
import {
  sendAboutUsDescription,
  getAboutUsDescription,
} from "@/endPoints/aboutUs";
import AnimatedLoader from "../UI/AnimatedLoader.vue";

export default {
  components: {
    GrayButton,
    AnimatedLoader,
  },

  data() {
    return {
      enSectionDescription: "",
      arSectionDescription: "",

      isLoading: false,
    };
  },

  methods: {
    async submitForm() {
      if (this.enSectionDescription == "") {
        this.$noty.warning("Please enter english section description");
      } else if (this.enSectionDescription.length > 450) {
        this.$noty.warning(
          "Section description can't be more than 450 characters"
        );
      } else if (this.arSectionDescription == "") {
        this.$noty.warning("Please enter arabic section description");
      } else if (this.arSectionDescription.length > 450) {
        this.$noty.warning(
          "Section description can't be more than 450 characters"
        );
      } else {
        this.isLoading = true;
        const res = await sendAboutUsDescription(
          this.arSectionDescription,
          this.enSectionDescription
        );
        if (res.data) {
          this.isLoading = false;
          this.$noty.success(
            "About us descripton has been submitted successfully"
          );
        }
      }
    },

    async fetchAboutUsDescription() {
      this.isLoading = true;
      const res = await getAboutUsDescription();

      if (res.data) {
        this.isLoading = false;
        const data = res.data.data;
        this.arSectionDescription = data.about_us_description_ar;
        this.enSectionDescription = data.about_us_description_en;
      }
    },
  },

  mounted() {
    this.fetchAboutUsDescription();
  },
};
</script>

<style scoped>
.about_us .textarea_control {
  margin-bottom: 0px;
}
</style>
