<template>
  <div class="contact_us">
    <!-- Animated Loader -->
    <animated-loader :loading="isLoading" />

    <h4 class="section_title">Contact Us</h4>
    <button class="single_tab">contact information</button>

    <div class="content_wrapper">
      <form @submit.prevent="submitForm">
        <div class="english_inputs">
          <div class="textarea_control">
            <label>Company Location</label>
            <textarea
              placeholder="Enter company location"
              v-model="enCompanyLocation"
              @keypress="$checkTextareaCharacters(enCompanyLocation, $event)"
            ></textarea>
            <span class="hint">
              {{ enCompanyLocation.length }}/450 (Maximum 450 characters)
            </span>
          </div>
          <div class="form_row">
            <div>
              <div class="input_control">
                <label>Company Phone Number</label>
                <input
                  type="text"
                  placeholder="Enter company phone number"
                  v-model="enCompanyphoneNumber"
                  @keypress="
                    $checkInputCharacters(enCompanyphoneNumber, $event)
                  "
                />
                <span class="hint">
                  {{ enCompanyphoneNumber.length }}/50 (Maximum 50 characters)
                </span>
              </div>
            </div>
            <div>
              <div class="input_control">
                <label>Company Whatsapp Number</label>
                <input
                  type="text"
                  placeholder="Enter company whatsapp number"
                  v-model="enCompanyWhatsappNumber"
                  @keypress="
                    $checkInputCharacters(enCompanyWhatsappNumber, $event)
                  "
                />
                <span class="hint">
                  {{ enCompanyWhatsappNumber.length }}/50 (Maximum 50
                  characters)
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="line"></div>

        <div class="arabic_inputs">
          <div class="textarea_control">
            <label>عنوان الشركة</label>
            <textarea
              placeholder="ادخل عنزان الشركة"
              v-model="arCompanyLocation"
              @keypress="$checkTextareaCharacters(arCompanyLocation, $event)"
            ></textarea>
            <span class="hint">
              {{ String(arCompanyLocation.length).toIndiaDigits() }}/{{
                "450".toIndiaDigits()
              }}
              (أقصى عدد من الأحرف {{ "450".toIndiaDigits() }})
            </span>
          </div>
          <div class="form_row">
            <div>
              <div class="input_control">
                <label>رقم واتساب الشركة</label>
                <input
                  type="text"
                  placeholder="ادخل رقم واتساب الشركة"
                  v-model="arCompanyWhatsappNumber"
                  @keypress="
                    $checkInputCharacters(arCompanyWhatsappNumber, $event)
                  "
                />
                <span class="hint"
                  >(أقصى عدد من الأحرف {{ "50".toIndiaDigits() }}){{
                    String(arCompanyWhatsappNumber.length).toIndiaDigits()
                  }}/{{ "50".toIndiaDigits() }}
                </span>
              </div>
            </div>
            <div>
              <div class="input_control">
                <label>رقم هاتف الشركة</label>
                <input
                  type="text"
                  placeholder="ادخل رقم هاتف الشركة"
                  v-model="arCompanyphoneNumber"
                  @keypress="
                    $checkInputCharacters(arCompanyphoneNumber, $event)
                  "
                />
                <span class="hint"
                  >(أقصى عدد من الأحرف {{ "50".toIndiaDigits() }}){{
                    String(arCompanyphoneNumber.length).toIndiaDigits()
                  }}/{{ "50".toIndiaDigits() }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <GrayButton text="Save&Submit" size="large" position="right" />
      </form>
    </div>
  </div>
</template>

<script>
import GrayButton from "@/components/UI/GrayButton.vue";
import {
  sendContactUsDetails,
  getContactUsDetails,
} from "@/endPoints/contactUs";
import AnimatedLoader from "@/components/UI/AnimatedLoader.vue";

export default {
  components: { GrayButton, AnimatedLoader },

  data() {
    return {
      enCompanyLocation: "",
      enCompanyphoneNumber: "",
      enCompanyWhatsappNumber: "",
      arCompanyLocation: "",
      arCompanyphoneNumber: "",
      arCompanyWhatsappNumber: "",

      isLoading: false,
    };
  },

  methods: {
    async submitForm() {
      if (this.enCompanyLocation == "") {
        this.$noty.warning("Please enter english company location");
      } else if (this.enCompanyLocation.length > 450) {
        this.$noty.warning(
          "Company location can't be more than 450 characters"
        );
      } else if (this.enCompanyphoneNumber == "") {
        this.$noty.warning("Please enter english company phone number");
      } else if (this.enCompanyphoneNumber.length > 50) {
        this.$noty.warning("Phone number can't be more than 50 characters");
      } else if (this.enCompanyWhatsappNumber == "") {
        this.$noty.warning("Please enter english company whatsapp number");
      } else if (this.enCompanyWhatsappNumber.length > 50) {
        this.$noty.warning("Whatsapp number can't be more than 50 characters");
      } else if (this.arCompanyLocation == "") {
        this.$noty.warning("Please enter arabic company location");
      } else if (this.arCompanyLocation.length > 450) {
        this.$noty.warning(
          "Company location can't be more than 450 characters"
        );
      } else if (this.arCompanyphoneNumber == "") {
        this.$noty.warning("Please enter arabic company phone number");
      } else if (this.arCompanyphoneNumber.length > 50) {
        this.$noty.warning("Phone number can't be more than 50 characters");
      } else if (this.arCompanyWhatsappNumber == "") {
        this.$noty.warning("Please enter arabic company whatsapp number");
      } else if (this.arCompanyWhatsappNumber.length > 50) {
        this.$noty.warning("Whatsapp number can't be more than 50 characters");
      } else {
        this.isLoading = true;
        const res = await sendContactUsDetails(
          this.arCompanyLocation,
          this.enCompanyLocation,
          this.enCompanyphoneNumber,
          this.enCompanyWhatsappNumber
        );

        if (res.data) {
          this.isLoading = false;
          this.$noty.success(
            "Contact us details has been submitted successfully"
          );
        }
      }
    },

    async fetchContactUsDetails() {
      this.isLoading = true;
      const res = await getContactUsDetails();
      const data = res.data.data;

      if (res.data) {
        this.isLoading = false;
        this.enCompanyLocation = data.address_en;
        this.arCompanyLocation = data.address_ar;
        this.enCompanyphoneNumber = data.phone;
        this.arCompanyphoneNumber = data.phone;
        this.enCompanyWhatsappNumber = data.whatsapp_phone;
        this.arCompanyWhatsappNumber = data.whatsapp_phone;
      }
    },
  },

  mounted() {
    this.fetchContactUsDetails();
  },
};
</script>

<style scoped></style>
