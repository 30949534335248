/* eslint-disable no-unused-vars */
import { api } from "@/axios.config";

export const createCategory = (name_ar, name_en) => {
  const res = api.post("api/admin/create_category", {
    name_ar,
    name_en,
  });

  if (res) {
    return res;
  } else return false;
};

export const getCategories = () => {
  const res = api.get("api/get_categories");

  if (res) {
    return res;
  } else return false;
};

export const deleteCategory = (categoryId) => {
  const res = api.delete(`api/admin/delete_category/${categoryId}`);

  if (res) {
    return res;
  } else return false;
};

export const addProject = (
  name_ar,
  name_en,
  description_ar,
  description_en,
  category_id,
  image,
  images
) => {
  const res = api.post("api/admin/create_project", {
    name_ar,
    name_en,
    description_ar,
    description_en,
    category_id,
    image,
    images,
  });

  if (res) {
    return res;
  } else return false;
};

export const getProjects = () => {
  const res = api.get("api/get_projects");

  if (res) {
    return res;
  } else return false;
};

export const deleteProject = (projectId) => {
  const res = api.delete(`api/admin/delete_project/${projectId}`);

  if (res) {
    return res;
  } else return false;
};

export const getSingleProject = (projectId) => {
  const res = api.get(`api/get_project/${projectId}`);

  if (res) {
    return res;
  } else return false;
};

export const editSingleProject = (
  projectId,
  name_ar,
  name_en,
  description_ar,
  description_en,
  image,
  images
) => {
  const res = api.put(`api/admin/update_project/${projectId}`, {
    name_ar,
    name_en,
    description_ar,
    description_en,
    image,
    images,
  });

  if (res) {
    return res;
  } else return false;
};

export const deleteGalleryImage = (imageId) => {
  const res = api.delete(`api/admin/delete_project_image/${imageId}`);

  if (res) {
    return res;
  } else return false;
};
