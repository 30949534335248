<template>
  <div class="add_service">
    <!-- Animated Loader -->
    <animated-loader :loading="isLoading" />

    <h4 class="section_title">Home/Services</h4>

    <button class="single_tab">Add Service</button>

    <div class="content_wrapper">
      <form @submit.prevent="submitForm">
        <div class="english_inputs">
          <div class="form_row">
            <div>
              <div class="input_control upload_image">
                <label> Service Icon </label>
                <input
                  id="logoPath"
                  type="text"
                  class="disabled_input"
                  disabled
                  placeholder="Choose file"
                />
                <GrayButton text="Browse" size="small" browseBtn="en">
                  <input
                    @change="uploadLogo"
                    id="logoInput"
                    type="file"
                    accept="image/*"
                  />
                </GrayButton>
              </div>
            </div>
            <div>
              <div class="input_control">
                <label>Service Title</label>
                <input
                  type="text"
                  placeholder="Enter title"
                  v-model="enSectionTitle"
                  @keypress="$checkInputCharacters(enSectionTitle, $event)"
                />
                <span class="hint">
                  {{ enSectionTitle.length }}/50 (Maximum 50 characters)
                </span>
              </div>
            </div>
          </div>
          <div class="form_row">
            <div>
              <div class="input_control upload_image">
                <label> Description Photo </label>
                <input
                  id="imagePath"
                  type="text"
                  class="disabled_input"
                  disabled
                  placeholder="Choose file"
                />
                <GrayButton text="Browse" size="small" browseBtn="en">
                  <input
                    id="imageInput"
                    @change="uploadImage"
                    type="file"
                    accept="image/*"
                  />
                </GrayButton>
              </div>
            </div>
            <div></div>
          </div>
          <div class="textarea_control">
            <label>Section Description</label>
            <textarea
              placeholder="Enter section description"
              v-model="enSectionDescription"
              @keypress="$checkTextareaCharacters(enSectionDescription, $event)"
            ></textarea>
            <span class="hint">
              {{ enSectionDescription.length }}/450 (Maximum 450 characters)
            </span>
          </div>
        </div>

        <div class="line"></div>

        <div class="arabic_inputs">
          <div class="form_row">
            <div></div>
            <div>
              <div class="input_control">
                <label> عنوان الخدمة</label>
                <input
                  type="text"
                  placeholder=" أدخل عنوان"
                  dir="rtl"
                  v-model="arSectionTitle"
                  @keypress="$checkInputCharacters(arSectionTitle, $event)"
                />
                <span class="hint"
                  >(أقصى عدد من الأحرف {{ "50".toIndiaDigits() }}){{
                    String(arSectionTitle.length).toIndiaDigits()
                  }}/{{ "50".toIndiaDigits() }}
                </span>
              </div>
            </div>
          </div>

          <div class="textarea_control">
            <label>وصف الخدمة</label>
            <textarea
              placeholder="ادخل وصف الخدمة"
              dir="rtl"
              v-model="arSectionDescription"
              @keypress="$checkTextareaCharacters(arSectionDescription, $event)"
            ></textarea>
            <span class="hint">
              {{ String(arSectionDescription.length).toIndiaDigits() }}/{{
                "450".toIndiaDigits()
              }}
              (أقصى عدد من الأحرف {{ "450".toIndiaDigits() }})
            </span>
          </div>
        </div>

        <div class="action_btns">
          <button
            type="button"
            class="cancel_btn"
            @click="$modal.show('cancelAddServiceModal')"
          >
            Cancel
          </button>
          <GrayButton text="Confirm" size="large" />
        </div>
      </form>
    </div>

    <!-- Cancel Modal -->
    <modal name="cancelAddServiceModal">
      <div class="modal_content">
        <div class="img_wrapper">
          <img src="@/assets/icons/warning_icon.svg" alt="" />
        </div>
        <div class="text_content">
          <h4>Cancel Add service process?</h4>
          <p>You will not continue this process</p>
        </div>
        <div class="action_btns">
          <button @click="$modal.hide('cancelAddServiceModal')">
            Keep adding
          </button>
          <button @click="cancelProccess">Yes, Cancel</button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import GrayButton from "../UI/GrayButton.vue";
import { Addservice } from "@/endPoints/home";
import AnimatedLoader from "../UI/AnimatedLoader.vue";

export default {
  components: {
    GrayButton,
    AnimatedLoader,
  },

  data() {
    return {
      // Logo and Image
      uploadedLogo: "",
      uploadedImage: "",

      // Inputs
      enSectionTitle: "",
      enSectionDescription: "",
      arSectionTitle: "",
      arSectionDescription: "",

      isLoading: false,
    };
  },
  methods: {
    uploadLogo() {
      const logoInput = document.getElementById("logoInput");
      const logoPath = document.getElementById("logoPath");
      const serviceLogo = logoInput.files[0];
      logoPath.value = serviceLogo.name;

      const reader = new FileReader();
      reader.onloadend = () => {
        this.uploadedLogo = reader.result;
      };
      reader.readAsDataURL(serviceLogo);
    },

    uploadImage() {
      const imageInput = document.getElementById("imageInput");
      const imagePath = document.getElementById("imagePath");
      const descriptionImage = imageInput.files[0];
      imagePath.value = descriptionImage.name;

      const reader = new FileReader();
      reader.onloadend = () => {
        this.uploadedImage = reader.result;
      };
      reader.readAsDataURL(descriptionImage);
    },

    async submitForm() {
      if (this.uploadedLogo == "") {
        this.$noty.warning("Please select service icon");
      } else if (this.uploadedImage == "") {
        this.$noty.warning("Please select description image");
      } else if (this.enSectionTitle == "") {
        this.$noty.warning("Please enter english section title");
      } else if (this.enSectionDescription == "") {
        this.$noty.warning("Please enter english section description");
      } else if (this.enSectionTitle.length > 50) {
        this.$noty.warning("Section title can't be more than 50 characters");
      } else if (this.enSectionDescription.length > 450) {
        this.$noty.warning(
          "Section description can't be more than 450 characters"
        );
      } else if (this.arSectionTitle == "") {
        this.$noty.warning("Please enter arabic section title");
      } else if (this.arSectionDescription == "") {
        this.$noty.warning("Please enter arabic section description");
      } else if (this.arSectionTitle.length > 50) {
        this.$noty.warning("Section title can't be more than 50 characters");
      } else if (this.arSectionDescription.length > 450) {
        this.$noty.warning(
          "Section description can't be more than 450 characters"
        );
      } else {
        this.isLoading = true;

        const res = await Addservice(
          this.arSectionTitle,
          this.enSectionTitle,
          this.arSectionDescription,
          this.enSectionDescription,
          this.uploadedLogo,
          this.uploadedImage
        );

        if (res.data) {
          this.isLoading = false;
          this.$noty.success("Service has been added successfully");
          this.$router.push("/");
        }
      }
    },

    cancelProccess() {
      this.$router.push("/");
    },
  },
};
</script>

<style scoped></style>
