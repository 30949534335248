/* eslint-disable no-unused-vars */
import { api } from "@/axios.config";
import { setHeaderConfig } from "@/helpers";

export const login = async (email, password) => {
  let requestHeaders;
  const res = await api
    .post("api/admin/login", { email, password })
    .then((res) => {
      requestHeaders = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + res.data.data.token,
        },
      };

      setHeaderConfig(requestHeaders);

      if (res.status == 401) {
        alert("men3m");
      } else {
        return res;
      }
    });
  return res;
};
