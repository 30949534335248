// eslint-disable-next-line vue/multi-word-component-names
<template>
  <div class="tabs">
    <button
      :class="theSelectedView == tab ? 'active' : ''"
      @click="selectView(tab)"
      v-for="tab in tabs"
      :key="tab"
    >
      {{ tab }}
    </button>
  </div>
</template>

<script>
export default {
  name: "NavigationTabs",
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    selectView: {
      type: Function,
      required: false,
    },
    theSelectedView: {
      type: String,
      required: false,
    },
  },
};
</script>

<style scoped>
.tabs button {
  border: 0;
  outline: 0;
  width: 165px;
  height: 55px;
  background-color: transparent;
  color: #d3d3d3;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  font-size: 16px;
  cursor: pointer;
}

.tabs button.active {
  background-color: #333333;
}
</style>
