<template>
  <div class="not_found">
    <div class="content">
      <h3>Page not found</h3>
      <button @click="backHome">Back Home</button>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    backHome() {
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
.not_found {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.not_found h3 {
  font-size: 3rem;
}

.not_found button {
  width: 200px;
  height: 45px;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  position: relative;
  display: block;
  margin: 30px auto 0px;
  border: 0;
  outline: 0;
  background-color: #707070;
  color: #fff7f7;
  border-radius: 20px;
  cursor: pointer;
}
</style>
