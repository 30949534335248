/* eslint-disable no-unused-vars */
import { api } from "@/axios.config";

// Hero Section
export const sendHeroContent = (
  title_ar,
  title_en,
  description_ar,
  description_en
) => {
  const res = api.post("api/admin/create_header_content", {
    title_ar,
    title_en,
    description_ar,
    description_en,
    logo: "",
  });

  if (res) {
    return res;
  } else return false;
};

export const getHeroContent = () => {
  const res = api.get("api/get_header_content");

  if (res) {
    return res;
  } else return false;
};

export const uploadLogoimage = (logo) => {
  const res = api.post("api/admin/upload_logo", { logo });

  if (res) {
    return res;
  } else return false;
};

// Services
export const sendServicesDescription = (brief_ar, brief_en) => {
  const res = api.post("api/admin/create_service_brief", {
    brief_ar,
    brief_en,
  });

  if (res) {
    return res;
  } else return false;
};

export const getServicesDescription = () => {
  const res = api.get("api/get_service_brief");

  if (res) {
    return res;
  } else return false;
};

export const Addservice = (
  title_ar,
  title_en,
  description_ar,
  description_en,
  icon,
  image
) => {
  const res = api.post("api/admin/create_service", {
    title_ar,
    title_en,
    description_ar,
    description_en,
    icon,
    image,
  });

  if (res) {
    return res;
  } else return false;
};

export const getServices = () => {
  const res = api.get("api/get_services");

  if (res) {
    return res;
  } else return false;
};

export const getSingleService = (serviceId) => {
  const res = api.get(`api/get_service/${serviceId}`);

  if (res) {
    return res;
  } else return false;
};

export const updateService = (
  serviceId,
  title_ar,
  title_en,
  description_ar,
  description_en,
  icon,
  image
) => {
  const res = api.put(`api/admin/update_service/${serviceId}`, {
    title_ar,
    title_en,
    description_ar,
    description_en,
    icon,
    image,
  });

  if (res) {
    return res;
  } else return false;
};

export const deleteService = (serviceId) => {
  const res = api.delete(`api/admin/delete_service/${serviceId}`);

  if (res) {
    return res;
  } else return false;
};

// Clients
export const sendClientsDescription = (brief_ar, brief_en) => {
  const res = api.post("api/admin/create_client_brief", {
    brief_ar,
    brief_en,
  });

  if (res) {
    return res;
  } else return false;
};

export const getClientsDescription = () => {
  const res = api.get("api/get_client_brief");
  if (res) {
    return res;
  } else return false;
};

export const AddClient = (name_en, name_ar, image) => {
  const res = api.post("api/admin/create_client", {
    name_en,
    name_ar,
    image,
  });

  if (res) {
    return res;
  } else return false;
};

export const getClients = () => {
  const res = api.get("api/get_clients");

  if (res) {
    return res;
  } else return false;
};

export const getSingleClient = (clientId) => {
  const res = api.get(`api/get_client/${clientId}`);

  if (res) {
    return res;
  } else return false;
};

export const updateClient = (clientId, name_en, name_ar, image) => {
  const res = api.put(`api/admin/update_client/${clientId}`, {
    name_en,
    name_ar,
    image,
  });

  if (res) {
    return res;
  } else return false;
};

export const deleteClient = (clientId) => {
  const res = api.delete(`api/admin/delete_client/${clientId}`);

  if (res) {
    return res;
  } else return false;
};

// Fast facts
export const sendFastFacts = (
  description_ar,
  description_en,
  happy_clients,
  employees,
  expert_developers,
  successful_projects
) => {
  const res = api.post("api/admin/create_fast_facts", {
    description_ar,
    description_en,
    happy_clients,
    employees,
    expert_developers,
    successful_projects,
  });

  if (res) {
    return res;
  } else return false;
};

export const getFastFacts = () => {
  const res = api.get("api/get_fast_facts");

  if (res) {
    return res;
  } else return false;
};

export const sendEnVideo = (video_en) => {
  const res = api.post("api/admin/uploads_fast_facts_video_en", video_en);

  if (res) {
    return res;
  } else return false;
};

export const sendArVideo = (video_ar) => {
  const res = api.post("api/admin/uploads_fast_facts_video_ar", video_ar);

  if (res) {
    return res;
  } else return false;
};

export const AddBlog = (title, title_ar, article, article_ar, images) => {
  const res = api.post("api/admin/blogs", {
    title,
    title_ar,
    article,
    article_ar,
    images
  });

  if (res) {
    return res;
  } else return false;
};


export const getAllBlogs = () => {
  const res = api.get('api/blogs');

  if (res) {
    return res;
  } else return false;
};

export const deleteBlog = (blogId) => {
  const res = api.get('api/blogs/' + blogId);

  if (res) {
    return res;
  } else return false;
};
