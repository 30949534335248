<template>
  <modal name="warningModal">
    <div class="modal_content">
      <div class="img_wrapper">
        <img src="@/assets/icons/warning_icon.svg" alt="" />
      </div>
      <div class="text_content">
        <h4>{{ modalData.question }}</h4>
        <p>{{ modalData.warnning }}</p>
      </div>
      <div class="action_btns">
        <button type="button" @click="$modal.hide('warningModal')">
          {{ modalData.cancelBtnText }}
        </button>
        <button type="button" @click="modalData.actionFunc">
          {{ modalData.actionBtnText }}
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  props: ["modalData"],
};
</script>

<style scoped></style>
