var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.browseBtn)?_c('button',{staticClass:"gray_btn",class:[
      _vm.position == 'right' ? 'right' : '',
      _vm.size == 'large' ? 'large' : '',
      _vm.size == 'small' ? 'small' : '',
      _vm.browseBtn == 'en' ? 'browse_btn_en' : '',
      _vm.browseBtn == 'ar' ? 'browse_btn_ar' : '' ],attrs:{"type":"button"}},[_vm._t("default"),_vm._v(" "+_vm._s(_vm.text)+" ")],2):_vm._e(),(_vm.size == 'large')?_c('button',{staticClass:"gray_btn",class:[
      _vm.position == 'right' ? 'right' : '',
      _vm.size == 'large' ? 'large' : '' ]},[_vm._t("default"),_vm._v(" "+_vm._s(_vm.text)+" ")],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }