/* eslint-disable no-unused-vars */
import { api } from "@/axios.config";

export const sendContactUsDetails = (
  address_ar,
  address_en,
  phone,
  whatsapp_phone
) => {
  const res = api.post("api/admin/create_contact_us", {
    address_ar,
    address_en,
    phone,
    whatsapp_phone,
  });

  if (res) {
    return res;
  } else return false;
};

export const getContactUsDetails = () => {
  const res = api.get("api/get_contact_us");

  if (res) {
    return res;
  } else return false;
};
