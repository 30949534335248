<template>
  <div>
    <!-- Animated Loader -->
    <animated-loader :loading="isLoading" />

    <form @submit.prevent="submitForm">
      <div class="english_inputs">
        <div class="textarea_control">
          <label>Section Description</label>
          <textarea
            placeholder="Enter section description"
            v-model="enSectionDescription"
            @keypress="$checkTextareaCharacters(enSectionDescription, $event)"
          ></textarea>
          <span class="hint">
            {{ enSectionDescription.length }}/450 (Maximum 450 characters)
          </span>
        </div>
        <div class="form_row">
          <div>
            <div class="input_control upload_image">
              <label> Section's Video </label>
              <input
                id="enVideoPath"
                type="text"
                class="disabled_input"
                disabled
                placeholder="Choose file"
              />
              <GrayButton text="Browse" size="small" browseBtn="en">
                <input
                  @change="uploadEnVideo"
                  id="enFileInput"
                  type="file"
                  accept="video/*"
                />
              </GrayButton>
            </div>
            <span class="hint loading" v-if="loadingEnVideo"> Loading... </span>
          </div>
          <div></div>
        </div>
        <div class="form_row">
          <div>
            <div class="input_control">
              <label>Happy Clients</label>
              <input
                type="number"
                placeholder="Enter section headline"
                name="happyClients"
                v-model="happyClients"
                @keypress="$checkInputCharacters(happyClients, $event)"
              />
              <span class="hint">
                {{ happyClients.length }}/50 (Maximum 50 characters)
              </span>
            </div>
          </div>
          <div>
            <div class="input_control">
              <label>Employees</label>
              <input
                type="number"
                placeholder="Enter section headline"
                v-model="employees"
                @keypress="$checkInputCharacters(employees, $event)"
              />
              <span class="hint">
                {{ employees.length }}/50 (Maximum 50 characters)
              </span>
            </div>
          </div>
        </div>
        <div class="form_row">
          <div>
            <div class="input_control">
              <label>Expert Developers</label>
              <input
                type="number"
                placeholder="Enter section headline"
                v-model="expertDevelopers"
                @keypress="$checkInputCharacters(expertDevelopers, $event)"
              />
              <span class="hint">
                {{ expertDevelopers.length }}/50 (Maximum 50 characters)
              </span>
            </div>
          </div>
          <div>
            <div class="input_control">
              <label>Successful Projects</label>
              <input
                type="number"
                placeholder="Enter section headline"
                v-model="successfulProjects"
                @keypress="$checkInputCharacters(successfulProjects, $event)"
              />
              <span class="hint">
                {{ successfulProjects.length }}/50 (Maximum 50 characters)
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="line"></div>

      <div class="arabic_inputs">
        <div class="form_row">
          <div></div>
          <div>
            <div class="input_control upload_image">
              <label> فيديو القسم </label>
              <input
                id="arVideoPath"
                type="text"
                class="disabled_input"
                disabled
                placeholder="اختر ملف"
              />
              <GrayButton text="تصفح" size="small" browseBtn="ar">
                <input
                  @change="uploadArVideo"
                  id="arFileInput"
                  type="file"
                  accept="video/*"
                />
              </GrayButton>
            </div>
            <span class="hint loading" v-if="loadingArVideo"> ...تحميل </span>
          </div>
        </div>
        <div class="textarea_control">
          <label>وصف القسم</label>
          <textarea
            placeholder="ادخل وصف القسم"
            v-model="arSectionDescription"
            @keypress="$checkTextareaCharacters(arSectionDescription, $event)"
            dir="rtl"
          ></textarea>
          <span class="hint">
            {{ String(arSectionDescription.length).toIndiaDigits() }}/{{
              "450".toIndiaDigits()
            }}
            (أقصى عدد من الأحرف {{ "450".toIndiaDigits() }})
          </span>
        </div>
      </div>
      <GrayButton
        text="Save&Submit"
        size="large"
        position="right"
        ref="submit"
      />
    </form>
  </div>
</template>

<script>
import GrayButton from "../UI/GrayButton.vue";
import {
  sendFastFacts,
  getFastFacts,
  // eslint-disable-next-line no-unused-vars
  sendEnVideo,
  sendArVideo,
} from "@/endPoints/home";
import AnimatedLoader from "../UI/AnimatedLoader.vue";

export default {
  components: {
    GrayButton,
    AnimatedLoader,
  },

  data() {
    return {
      enUploadedViedo: "",
      arUploadedViedo: "",

      arSectionDescription: "",
      enSectionDescription: "",
      happyClients: "",
      employees: "",
      expertDevelopers: "",
      successfulProjects: "",

      loadingEnVideo: false,
      loadingArVideo: false,

      isLoading: false,
    };
  },

  methods: {
    uploadEnVideo() {
      const enFileInput = document.getElementById("enFileInput");
      const enVideoPath = document.getElementById("enVideoPath");
      // eslint-disable-next-line no-unused-vars
      const gray_btn = document.querySelector(".gray_btn");

      this.enUploadedViedo = enFileInput.files[0];
      enVideoPath.value = enFileInput.files[0].name;
    },

    uploadArVideo() {
      const arFileInput = document.getElementById("arFileInput");
      const arVideoPath = document.getElementById("arVideoPath");
      this.arUploadedViedo = arFileInput.files[0];
      arVideoPath.value = arFileInput.files[0].name;
    },

    async submitForm() {
      if (this.enSectionDescription == "") {
        this.$noty.warning("plaese enter english section description");
      } else if (this.enSectionDescription.length > 450) {
        this.$noty.warning(
          "Section description can't be more than 450 characters"
        );
      } else if (this.happyClients == "") {
        this.$noty.warning("plaese enter happy clients count");
      } else if (Number(this.happyClients) < 0) {
        this.$noty.warning("Happy clients count can't be a negative value");
      } else if (this.happyClients.length > 50) {
        this.$noty.warning("Happy clients can't be more than 50 characters");
      } else if (this.employees == "") {
        this.$noty.warning("plaese enter employees count");
      } else if (Number(this.employees) < 0) {
        this.$noty.warning("Employees count can't be a negative value");
      } else if (this.employees.length > 50) {
        this.$noty.warning("Employees count can't be more than 50 characters");
      } else if (this.expertDevelopers == "") {
        this.$noty.warning("plaese enter expert developers count");
      } else if (Number(this.expertDevelopers) < 0) {
        this.$noty.warning("Expert developers count can't be a negative value");
      } else if (this.expertDevelopers.length > 50) {
        this.$noty.warning(
          "Expert developers count can't be more than 50 characters"
        );
      } else if (this.successfulProjects == "") {
        this.$noty.warning("plaese enter successful projects count");
      } else if (Number(this.successfulProjects) < 0) {
        this.$noty.warning(
          "Successful projects count can't be a negative value"
        );
      } else if (this.successfulProjects.length > 50) {
        this.$noty.warning(
          "Successful projects count can't be more than 50 characters"
        );
      } else if (this.arSectionDescription == "") {
        this.$noty.warning("plaese enter arabic section description");
      } else if (this.arSectionDescription.length > 450) {
        this.$noty.warning(
          "Section description can't be more than 450 characters"
        );
      } else {
        // Upload english video
        if (this.enUploadedViedo != "") {
          const formData = new FormData();
          formData.append("video_en", this.enUploadedViedo);
          this.loadingEnVideo = true;
          this.isLoading = true;

          const res = await sendEnVideo(formData);
          this.isLoading = false;
          if (res) {
            this.loadingEnVideo = false;
            this.isLoading = false;
          }
        }
        // Upload arabic video
        if (this.arUploadedViedo != "") {
          const formData = new FormData();
          formData.append("video_ar", this.arUploadedViedo);
          this.loadingArVideo = true;
          this.isLoading = true;

          const res = await sendArVideo(formData);
          if (res) {
            this.loadingArVideo = false;
            this.isLoading = false;
          }
        }
        // Post the rest data
        this.isLoading = true;
        const res = await sendFastFacts(
          this.arSectionDescription,
          this.enSectionDescription,
          this.happyClients,
          this.employees,
          this.expertDevelopers,
          this.successfulProjects
        );

        if (res) {
          this.isLoading = false;
          this.$noty.success("Fast facts has been sumbitted successfully");
        }
      }
    },

    async fetchFastFacts() {
      this.isLoading = true;
      const res = await getFastFacts();

      if (res) {
        this.isLoading = false;
        const data = res.data.data;
        this.arSectionDescription = data.description_ar;
        this.enSectionDescription = data.description_en;
        this.happyClients = data.happy_clients;
        this.employees = data.employees;
        this.expertDevelopers = data.expert_developers;
        this.successfulProjects = data.successful_projects;
      }
    },
  },

  mounted() {
    this.fetchFastFacts();
  },
};
</script>

<style scoped>
.loading {
  font-size: 14px !important;
  animation: 2s ease infinite loading;
}

@keyframes loading {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
</style>
