import { render, staticRenderFns } from "./ChoosedView.vue?vue&type=template&id=7184dd83&scoped=true&"
import script from "./ChoosedView.vue?vue&type=script&lang=js&"
export * from "./ChoosedView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7184dd83",
  null
  
)

export default component.exports